export class SessionManagementApi {
    httpRequest;
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get All Active Sessions
     * Returns all active user sessions
     * @returns any Successful Response
     * @throws ApiError
     */
    getAllActiveSessions() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/sessions',
        });
    }
    /**
     * Get Session Stats
     * Get stats on active client sessions
     * @returns any Successful Response
     * @throws ApiError
     */
    getSessionStats() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/sessionsstats',
        });
    }
}
