import { util } from '@clientio/rappid';
import { createXrefLink, XRefLink } from './CustomShapes/customShapeFunctions';
import axiosIns from "@/libs/axios";
let firstRun = true;
function collapseAbstractNodes(nodes, rootId, hiddenNodeIds, edges, hiddenNodeIdsRef) {
    if (firstRun) {
        firstRun = false;
        nodes.forEach(node => {
            if (node.id !== rootId && node.type === 'FunctionNode') {
                hiddenNodeIds.push(...edges.filter(e => e.source === node.id).map(e => e.target));
            }
        });
        hiddenNodeIdsRef.value = hiddenNodeIds;
    }
}
export function addXrefLink(graph, source, target, colour, label = '') {
    return createXrefLink(graph.getCell(source), graph.getCell(target), colour, label);
}
export function zoomView(paperScroller, evt, x, y, delta) {
    evt.preventDefault();
    paperScroller.zoom(delta * 0.1, {
        min: 0.15, max: 1, grid: 0.1, ox: x, oy: y,
    });
}
export function nodeClickEvent(options) {
    // Handles the click event on any cell/node on the graph
    const { clickedNode, clickEvent, isCtrlKeyPressed, jointSelection, sidebarConditionals, vuexStore, selectedNodesArray } = options;
    if (clickedNode.model.isLink())
        return;
    clickEvent.preventDefault();
    if (clickEvent.ctrlKey) {
        if (jointSelection.collection.models.includes(clickedNode.model)) {
            jointSelection.collection.remove(clickedNode.model);
        }
        else {
            jointSelection.collection.add(clickedNode.model);
        }
    }
    else {
        // Do single click action with no ctrl
        jointSelection.collection.set([clickedNode.model]);
    }
    // Update nodes collection used by sidebars
    selectedNodesArray.value = jointSelection.collection.models.map((selectedGraphNode) => { return selectedGraphNode.id; });
    const count = jointSelection.collection.models.length;
    // Check if no nodes are selected yet
    if (count === 0) {
        closeSidebars(sidebarConditionals);
    }
    else if (count === 1) {
        // Do single actions
        openSelectedBNSidebar(sidebarConditionals, vuexStore, clickedNode.model);
        singleNodeClickActions(clickedNode.model, selectedNodesArray, jointSelection);
    }
    else if (count > 1) {
        // Do bulk actions
        openSidebar(sidebarConditionals);
    }
}
export function openSidebar(sidebarConditionals) {
    const { single, bulk } = sidebarConditionals;
    single.value = !bulk;
    bulk.value = bulk;
}
function closeSidebars(sidebarConditionals) {
    const { single, bulk } = sidebarConditionals;
    single.value = false;
    bulk.value = false;
}
export async function reorderSiblings(store, bnId, parentId, siblingRank) {
    return axiosIns.post(`/api/v2/behaviour/reorder_siblings/${bnId}`, {
        model: store.state.model.id,
        parentId,
        siblingRank,
    });
}
export async function integrateNodes(store, dragNode, dropNode, bt_id) {
    return axiosIns.post('/api/v2/behaviour/integrate', {
        model: store.state.model.id,
        source: dragNode,
        target: dropNode,
        source_tree: bt_id,
        rel_type: 'sequence',
    });
}
export async function moveNodes(dragNode, dropNode, bt_id) {
    return axiosIns.post('/api/v2/behaviour/move', {
        source: dragNode,
        target: dropNode,
        source_tree: bt_id,
        rel_type: 'sequence',
    });
}
export async function copyNodes(dragNode, dropNode, bt_id, with_children) {
    return axiosIns.post('/api/v2/behaviour/copy_node', {
        bt: bt_id,
        source: dragNode,
        target: dropNode,
        rel_type: 'sequence',
        with_children
    });
}
// ---- Generic Functions Section ----
// For functions that may be reused internally in this file
function singleNodeClickActions(clickedNode, selectedNodesArray, jointSelection) {
    // Deselect any selected nodes
    selectedNodesArray.value = [];
    jointSelection.collection.reset([]);
    // Select the node
    // Make sure clickedNode is already node.model
    selectedNodesArray.value.push(clickedNode.id);
    jointSelection.collection.add(clickedNode);
}
export function createDisplayNameHtml(node) {
    const x = node;
    let result = "";
    // Create a display name for the behaviour
    if (x.type === 'Quantification') {
        result = `<b>${x.display_cpt_name}</b> in <b>${x.set}</b>`;
        if (x.behaviour_name === '||') {
            result = `For each ${result}`;
        }
        else {
            result = `If some ${result}`;
        }
    }
    else if (x.type === 'Quantity') {
        result = `For up to ${x.quantity} of ${x.display_cpt_name} in ${x.set}`;
    }
    else if (x.type === 'Input' || x.type === 'Output') {
        result = `<h6>${x.display_cpt_name}</h6>`;
        result += x.io_resource_name ? `<p>${x.io_resource_name}</p>` : `<p>${x.io_resource}</p>`;
    }
    else {
        result = x.type !== 'FunctionNode' ? `<h6>${x.display_cpt_name}</h6>` : '<h6></h6>';
        result += (x.negated && (x.negated === 'true' || x.negated === 'True' || x.negated === true)) ? `<p>NOT (<i>${x.behaviour_name})</i>` : `<p><i>${x.behaviour_name}</i>`;
    }
    if (x.type !== 'Quantification') {
        if (x.type === 'Input' || x.type === 'Output') {
            // Create a display name for rel parts
            if (x.rel_parts && x.rel_parts.length > 0) {
                x.rel_parts.forEach(r => {
                    result += `<p class="rel">${r.name}</p>`;
                });
            }
        }
        else {
            if (x.rel_parts && Array.isArray(x.rel_parts)) {
                x.rel_parts.forEach(r => {
                    result += (r && r.preposition !== '') ? ` ${r.preposition}` : '';
                    if (r.instance_name && r.instance_name !== '') {
                        result += ` <b>${r.object_name}</b>#${r.instance_name}`;
                    }
                    else {
                        result += ` <b>${r.object_name}</b>#`;
                    }
                    if (r.attribute && r.attribute !== '') {
                        result += `.${r.attribute}`;
                    }
                });
            }
            result += '</p>';
        }
    }
    return util.sanitizeHTML(result);
}
export function openSelectedBNSidebar(sidebarConditionals, vuexStore, clickedNode) {
    const { single, loading, bulk, } = sidebarConditionals;
    bulk.value = false;
    single.value = true;
    loading.value = true;
    vuexStore.dispatch('behaviours/selectBehaviourNode', clickedNode.id)
        .then(() => {
        loading.value = false;
    });
}
export function focusNode(nodeElement, scroller, selectedNodesArray, jointSelection) {
    console.log('focus on node', nodeElement.id);
    // Get node coordinates
    const bbox = nodeElement.getBBox();
    // Animation that scrolls to node center
    scroller.transitionToPoint(bbox.center().x, bbox.center().y, {
        duration: '600ms',
        timingFunction: 'cubic-bezier(0, 0, 0, 1.0)',
    });
    // scroller.scrollToElement(nodeElement, { animation: { duration: 600 }})
    singleNodeClickActions(nodeElement, selectedNodesArray, jointSelection);
}
export { createXrefLink, XRefLink };
