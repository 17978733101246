<template>
  <b-modal
    id="create-alias-modal"
    title="Create Alias"
    size="lg"
    @ok="onSubmit"
    @cancel="clearForm"
  >
    <form ref="create-alias-modal-form" @submit.stop.prevent="onSubmit">
      <b-form-group
        label="Alias name"
        label-for="txtAliasName"
        invalid-feedback="An alias name is required"
        :state="aliasNameState"
      >
        <b-form-input
          id="txtAliasName"
          v-model="name"
          :state="aliasNameState"
          required
          @keydown.enter.native="onSubmit"
        />
      </b-form-group>
    </form>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-secondary" @click="cancel">
        Discard
      </b-button>
      <b-button variant="success" @click="ok">
        {{ loading ? 'Creating Alias...' : 'Create Alias' }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      name: '',
      aliasNameState: null,
      loading: false,
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs['create-alias-modal-form'].checkValidity()
      this.aliasNameState = valid
      return valid
    },
    onSubmit(evt) {
      evt.preventDefault()
      if (!this.checkFormValidity()) {
        return
      }
      this.loading = true
      const selectedId = this.selected_entity2.context.details.id
      const payload = {
        name: this.name,
        model: this.$store.state.model.id,
      }
      this.$http
        .post(`/api/v2/domain_model/add_alias/${selectedId}`, payload)
        .then(result => {
          this.clearForm()
          this.$store.dispatch('domainModel/invalidateCache')
          this.$store.dispatch('domainModel/selectEntity2', selectedId)
          this.$store.dispatch('domainModel/getComponents')
          this.$bvModal.hide('create-alias-modal')
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred when creating the alias',
              text: `${error}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { this.loading = false })
    },
    clearForm() {
      this.loading = false
      this.name = ''
      this.aliasNameState = null
    },
  },
}
</script>
