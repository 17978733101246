<template>
  <div>
    <h5>Generation Control</h5>
    <b-form-group>
      <b-form-checkbox
        id="ch-checkbox"
        v-model="value.with_children"
        :checked="value.with_children"
        name="ch-checkbox"
        switch
      >
        Generate Requirements for Children
      </b-form-checkbox>
    </b-form-group>
    <specifications-form-select v-model="value.specification" label="Select Target Specification:" @input="specChange" />
    <div v-if="target_spec_requirements && target_spec_requirements.requirements && target_spec_requirements.requirements.length">

      <h5>Select where to generate requirements...</h5>
      <b-table
        :fields="table_fields"
        :items="target_spec_requirements.requirements"
        sticky-header
        responsive
        selectable
        select-mode="single"
        style="max-height: 50vh"
        @row-selected="rowSelected"
      >
        <template v-slot:cell(object_text)="data">
          <span v-sanitized-html="data.value" />
        </template>
      </b-table>
      <hr>
      <h5>Select Placement</h5>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio v-model="value.direction" :aria-describedby="ariaDescribedby" name="some-radios" value="before">
          Before
        </b-form-radio>
        <b-form-radio v-model="value.direction" :aria-describedby="ariaDescribedby" name="some-radios" value="after">
          After
        </b-form-radio>
        <b-form-radio v-model="value.direction" :aria-describedby="ariaDescribedby" name="some-radios" value="child">
          Child
        </b-form-radio>
      </b-form-group>
      <h3 style="text-align: center">
      <span v-if="value.dest_target">
        <strong>Action:</strong> Generate Quality Attribute requirements for {{ component.context.details.name }} {{ value.direction }} {{ value.dest_target_disp }}
      </span>
        <span v-else>
        Select Requirement For Placement
      </span>
      </h3>
    </div>
    <div v-else>
      <div id="spec_requirements">
        <span >
        Specification has no requirements.
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import SpecificationsFormSelect from '@/components/Specifications/Forms/SelectOld.vue'

export default {
  name: 'GenerateRequirementFormLite',
  components: {
    SpecificationsFormSelect,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    component: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState({
      selected_entity: state => state.domainModel.selected_entity2,
      requirements: state => state.requirements.requirements,
      specifications: state => state.specifications.specifications,
      target_spec_requirements: state => state.requirements.specificationRequirements,
    }),
    visible_requirements() {
      return this.requirements.filter(({ specification }) => specification === this.value.specification)
    },
    spec_opts() {
      return this.specifications.map(x => ({ value: x.id, text: x.qualified_name }))
    },
  },
  mounted() {
    this.$store.dispatch('specifications/fetchSpecificationsSimple')
  },
  methods: {
    rowSelected(rows) {
      if (rows[0] && rows[0].id) {
        this.$emit('input', { ...this.value, ...{ dest_target: rows[0].id, dest_target_disp: rows[0].display_id } })
      }
    },
    specChange(spec) {
      const vueApp = this
      console.debug('Spec selected:', spec)
      vueApp.$store.dispatch('requirements/getSpecificationRequirements', spec)
        .then(() => {
          console.debug('Spec requirements: ', vueApp.target_spec_requirements)
        })
    },
  },
  data() {
    return {
      table_fields: [
        {
          key: 'section',
          label: 'Section',
        },
        {
          key: 'display_id',
          label: 'ID',
        },
        {
          key: 'object_text',
          label: 'Requirement',
        },
        {
          key: 'priority',
          label: 'Priority',
        },
      ],
    }
  },
}
</script>

<style scoped>
#spec_requirements {
  min-height: 200px;
}
</style>
