import { render, staticRenderFns } from "./TipTapToolbarMenu.vue?vue&type=template&id=7a572b42&scoped=true"
import script from "./TipTapToolbarMenu.vue?vue&type=script&lang=js"
export * from "./TipTapToolbarMenu.vue?vue&type=script&lang=js"
import style0 from "./TipTapToolbarMenu.vue?vue&type=style&index=0&id=7a572b42&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a572b42",
  null
  
)

export default component.exports