export default [
  {
    headerKey: 7,
    header: 'Administration',
  },
  {
    title: 'User Management',
    route: '',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Users',
        route: 'admin_users',
      },
      {
        title: 'Active Sessions',
        route: 'admin_session_management',
      },
    ],
  },
  {
    title: 'Announcements',
    route: 'admin_banner',
    icon: 'RadioIcon',
  },
]
