<template>
  <b-modal
    id="create-instance-modal"
    title="Create Instance"
    size="lg"
    @show="onShow"
  >
    <div class="d-inline-flex w-100 justify-content-between">
      <div class="w-75 mr-50">
        <label for="create-instance-modal-name">Instance name</label>
        <b-form-input
          id="create-instance-modal-name"
          v-model="instanceName"
          :disabled="loading"
          placeholder="Instance name"
        />
      </div>
      <div>
        <label for="create-instance-modal-version">Version</label>
        <b-form-input
          id="create-instance-modal-version"
          v-model="instanceVersion"
          :disabled="loading"
          placeholder="Instance version"
        />
      </div>
    </div>

    <b-form-group
      label="Description"
      label-for="create-instance-modal-description"
      class="mt-1"
    >
      <tip-tap-editor
        id="create-instance-modal-description"
        v-model="instanceDescription"
        :disabled="loading"
        :allow-image-upload="false"
        min-height="8"
        max-height="8"
        placeholder="Describe this instance..."
      />
    </b-form-group>

    <template #modal-footer="{ cancel }">
        <!--<b-form-checkbox-->
        <!--  v-model="saveUserInput"-->
        <!--  switch-->
        <!--  class="align-self-center custom-control-info"-->
        <!--&gt;-->
        <!--  <span class="text-nowrap">-->
        <!--    Remember values-->
        <!--  </span>-->
        <!--</b-form-checkbox>-->

        <b-button variant="outline-secondary" @click="cancel">
          Discard
        </b-button>
        <b-button variant="success" :disabled="loading || !instanceName.trim()" @click="onSubmit">
          {{ loading ? 'Creating Instance...' : 'Create Instance' }}
        </b-button>
    </template>
  </b-modal>
</template>

<script>
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import axiosIns from '@/libs/axios'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  computed,
  ref,
} from '@vue/composition-api'

export default {
  name: 'InstanceCreateModal',
  components: {
    TipTapEditor,
  },
  setup(props, context) {
    const loading = ref(false)
    // const saveUserInput = ref(true)
    const instanceName = ref('')
    const instanceVersion = ref('')
    const instanceDescription = ref('')

    const onSubmit = () => {
      loading.value = true

      const { modelId } = router.currentRoute.params
      const selectedEntity = computed(store.state.domainModel.selected_entity2)
      const params = {
        model: modelId,
        instances: [{
          name: instanceName.value.trim(),
          version: instanceVersion.value.trim(),
          description: instanceDescription.value,
        }],
      }

      axiosIns
        .post(`/api/v2/domain_model/components/${store.state.domainModel.selected_entity2.context.details.id}/instances`, params)
        .then(({ data }) => {
          context.emit('created', data)
          context.root.$bvModal.hide('create-instance-modal')
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred when creating a new instance',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { loading.value = false })
    }

    const onShow = () => {
      // if (saveUserInput.value === false) {
      loading.value = false
      instanceName.value = ''
      instanceVersion.value = ''
      instanceDescription.value = ''
      // }
    }

    return {
      loading,
      // saveUserInput,
      instanceName,
      instanceVersion,
      instanceDescription,

      onSubmit,
      onShow,
    }
  },
}
</script>
