<template>
  <div id="SessionManagement" class="">
    <div class="w-100 d-inline-flex justify-content-between align-items-center mb-1">
      <div>
        <h2>Manage Active Sessions</h2>
        <p>
          A user session represents the duration in which a user is authenticated and
          actively logged into a system, encompassing their identity and permissions until
          they log out or the session expires.
        </p>
      </div>
      <b-button-group>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          title="Refresh session list"
          variant="info"
          size="sm"
          @click="fetchSessions"
        >
          <feather-icon icon="RefreshCwIcon" />
        </b-button>
      </b-button-group>
    </div>

    <div v-if="loading">
      <div class="w-100 d-inline-flex justify-content-center mt-4">
        <div class="d-flex flex-column align-items-center">
          <h5 class="text-warning mb-2">
            <atom-spinner class="animate-pulse" />
          </h5>
          <h5>
            Loading Sessions...
          </h5>
        </div>
      </div>
    </div>
    <div v-else class="w-100 d-flex justify-content-around">
      <b-card no-body class="w-100">
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">

            <!-- Sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="1"
              label-for="sortBySelect"
              label-class="mt-50 mr-50 pl-0"
              class="mb-0 text-nowrap"
              style="min-width: 28rem"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
            <!-- ./Sorting  -->

            <!-- Search -->
            <b-form-group
              label="Search"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              label-class="mt-50"
              class="mb-0"
              style="width: 40rem"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder=""
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
             <!--./Search -->
          </div>
        </b-card-body>

        <b-table
          responsive
          striped
          class="position-relative"
          :items="allSessions"
          :fields="sessionTableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(username)="{ item }">
            <router-link
              :to="{ name: 'manage_user', params: { userId: item.userId } }"
              class="custom-link"
            >
              <span class="select-all">
                {{ item.username }}
              </span>
            </router-link>
            (<span class="select-all text-nowrap">
              {{ item.userId }}
            </span>)
          </template>

          <template #cell(start)="{ item }">
            <span :title="$options.filters.formatDate(item.start)">
              {{ item.start | diffForHumans }}
            </span>
          </template>

          <template #cell(lastAccess)="{ item }">
            <span :title="$options.filters.formatDate(item.lastAccess)">
              {{ item.lastAccess | diffForHumans }}
            </span>
          </template>

          <template #cell(id)="{ item }">
            <!-- Session ID -->
            <span class="select-all text-nowrap">
              {{ item.id }}
            </span>
          </template>

          <template #cell(clients)="{ item }">
            <!-- Kompozition Clients -->
            <ul class="m-0 pl-0">
              <li v-for="client in item.clients" :key="client">
                <span>{{ client }}</span>
              </li>
            </ul>
          </template>

          <template v-slot:cell(actions)="{ item }">
            <b-button
              variant="flat-danger"
              :title="`Sign out ${item.username}`"
              @click="signUserOut(item.id)"
            >
              <feather-icon
                icon="TrashIcon"
                class="text-danger"
              />
            </b-button>
          </template>

          <template #empty>
            <div class="text-center my-2">
              <p>
                There are no active sessions.
              </p>
            </div>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- Page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-class="mt-50"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
          <!-- ./Page length -->

          <!-- Pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
          <!-- ./Pagination -->
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, ref } from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

export default {
  name: 'SessionManagement',
  components: {
    AtomSpinner,
  },
  directives: { Ripple },
  setup(props, context) {
    const loading = ref(false)
    const allSessions = ref([])
    const selectedUserId = ref('')

    const fetchSessions = () => {
      loading.value = true
      coreService.sessionManagementApi.getAllActiveSessions()
        .then(response => { allSessions.value = response })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to fetch user sessions',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          loading.value = false
        })
    }
    fetchSessions()

    const signUserOut = async sessionId => {
      coreService.adminCliApi.deleteSession(sessionId)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted user session',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to delete user session',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { fetchSessions() })
    }

    const perPage = 20
    const pageOptions = [10, 20, 50, 100]
    const totalRows = computed(() => allSessions.value.length)
    const currentPage = 1
    const sortBy = 'last_access'
    const sortDesc = false
    const sortDirection = 'desc'
    const filter = null
    const filterOn = []
    const sessionTableFields = [
      { key: 'username', label: 'User', sortable: true },
      { key: 'start', label: 'Start Time', sortable: true },
      { key: 'lastAccess', label: 'Last Access', sortable: true },
      { key: 'ipAddress', label: 'IP Address', sortable: true },
      { key: 'rememberMe', label: 'Remember Me', sortable: true },
      { key: 'id', label: 'Session ID', sortable: true },
      { key: 'clients', label: 'Kompozition Clients', sortable: false },
      {
        key: 'actions',
        label: '',
        thClass: 'text-center',
        tdClass: 'text-center',
        sortable: false,
      },
    ]
    const sortOptions = sessionTableFields
      .filter(f => f.sortable)
      .map(f => ({ text: f.label, value: f.key }))

    return {
      loading,
      allSessions,
      fetchSessions,

      selectedUserId,
      signUserOut,

      sessionTableFields,
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      sortOptions,
    }
  },
}
</script>

<style scoped lang="scss">
.custom-link {
  color: rgb(87, 157, 255);
  :hover {
    text-decoration: underline !important;
    color: rgb(87, 157, 255);
  }
}
</style>
