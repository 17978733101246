<template>
  <b-modal
    id="modal__BehaviourTreeCreate"
    title="Create Behaviour Tree"
    size="lg"
    :ok-title="loading ? 'Loading...' : 'Create Behaviour Tree'"
    :ok-disabled="loading"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @show="onShow"
    @ok.prevent="onSubmit"
  >
    <b-form-group
      label="Name"
      label-for="bt-name"
    >
      <b-form-input
        id="bt-name"
        v-model="formFields.name"
        data-cy="txt-bt-create-name"
        placeholder="Behaviour Tree name..."
      />
    </b-form-group>

    <b-form-group
      label="Description"
      label-for="bt-description"
    >
      <b-textarea
        id="bt-description"
        v-model="formFields.description"
        placeholder="Describe the Behaviour Tree..."
        rows="5"
      />
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group
          label="Status"
          label-for="bt-status"
        >
          <b-form-select
            id="bt-status"
            v-model="formFields.status"
            :options="statusOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Tag"
          label-for="bt-tag"
        >
          <b-form-input
            id="bt-tag"
            v-model="formFields.tag"
            placeholder="Enter a single tag..."
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default {
  name: 'CreateBehaviourTreeModal',
  setup(props, context) {
    const loading = ref(false)
    const formFields = ref({
      name: '',
      description: '',
      status: 'Draft',
      tag: '',
    })
    // Options for selecting the status field
    const statusOptions = [
      { value: 'Draft', text: 'Draft' },
      { value: 'Review', text: 'Review' },
      { value: 'Published', text: 'Published' },
    ]

    function onShow() {
      loading.value = false
      formFields.value = {
        name: '',
        description: '',
        status: 'Draft',
        tag: '',
      }
    }
    const onSubmit = () => {
      loading.value = true
      store
        .dispatch('behaviourTrees/createBehaviourTree', formFields.value)
        .then(data => {
          if (data) {
            context.root.$bvModal.hide('modal__BehaviourTreeCreate')
            router.push({ name: 'joint_mbse_tree', params: { behaviourTreeId: data.data.id } })
          }
        })
        .finally(() => { loading.value = false })
    }

    return {
      loading,
      formFields,
      statusOptions,
      onShow,
      onSubmit,
    }
  },
}
</script>
