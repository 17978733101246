const state = {
  ontologyPopupShow: true,
  ontologyPopupDelayMs: 700,
}

const getDefaultState = () => (state)
const prefix = 'PREFS_'
const mutations = {
  LOAD_ALL: state => {
    Object.keys(state).forEach(k => {
      const storedValue = localStorage.getItem(`${prefix}${k.toUpperCase()}`)
      if (storedValue !== null) {
        state[k] = JSON.parse(storedValue)
      }
    })
  },
}
const actions = {
  loadAll: ({ commit }) => commit('LOAD_ALL'),
}

// eslint-disable-next-line no-return-assign
Object.keys(state).forEach(k => mutations[k.toUpperCase()] = (state, value) => {
  state[k] = value
  localStorage.setItem(`${prefix}${k.toUpperCase()}`, value)
})
// eslint-disable-next-line no-return-assign
Object.keys(state).forEach(k => actions[k] = ({ commit }, value) => {
  commit(k.toUpperCase(), value)
})

const getters = {}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
  getters,
}
