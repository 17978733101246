<template>
  <div>
    <div v-if="type === 'interface'" />
    <div v-else>
      <b-row>
        <b-col cols="3">
          <h5>Type of Requirements to Generate</h5>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio v-model="value.type" :aria-describedby="ariaDescribedby" name="type-radios" value="story">
              User Stories
            </b-form-radio>
            <b-form-radio v-model="value.type" :aria-describedby="ariaDescribedby" name="type-radios" value="shall">
              Traditional Functional Requirements ("Shall" Statements)
            </b-form-radio>
            <b-form-radio v-model="value.type" :aria-describedby="ariaDescribedby" name="type-radios" value="feature">
              Feature List
            </b-form-radio>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <b-form-checkbox
              id="ch-checkbox"
              v-model="value.include_qa"
              :checked="value.include_qa"
              name="ch-checkbox"
              switch
            >
              Include Quality Attribute Requirements
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <h5>Defaults</h5>
      <b-row>
        <b-col cols="3">
          <b-form-group label="Select Performers:" label-for="perf-select">
            <b-form-select
              id="perf-select"
              v-model="value.selected_performers"
              :options="performer_opts"
              :select-size="4"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Select Enablers:" label-for="enabler-select">
            <b-form-select
              id="enabler-select"
              v-model="value.selected_enablers"
              :options="performer_opts"
              :select-size="4"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Select Beneficiaries:" label-for="beneficiary-select">
            <b-form-select
              id="beneficiary-select"
              v-model="value.selected_beneficiaries"
              :options="performer_opts"
              :select-size="4"
              multiple
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group label="Select Related Objectives:" label-for="objective-select">
            <b-form-select
              id="objective-select"
              v-model="value.selected_objectives"
              :options="objective_opts"
              :select-size="4"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Perform in accordance with Standards:" label-for="standards-select">
            <b-form-select
              id="standards-select"
              v-model="value.selected_standards"
              :options="standard_opts"
              :select-size="4"
              multiple
            />
          </b-form-group>
        </b-col>
      </b-row>
      <h5>Restrict</h5>
      <b-row>
        <b-col cols="3">
          <b-form-group label="Only Generate Requirements for the following Performers:" label-for="perf-restrict">
            <b-form-select
              id="perf-restrict"
              v-model="value.allowed_performers"
              :options="performer_opts"
              :select-size="4"
              multiple
            />
            <small class="w-100">All allocated performers will be used if none selected</small>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Only Generate Requirements for the following Enablers:" label-for="enabler-restrict">
            <b-form-select
              id="enabler-restrict"
              v-model="value.allowed_enablers"
              :options="performer_opts"
              :select-size="4"
              multiple
            />
            <small class="w-100">All allocated enablers will be used if none selected</small>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <specifications-form-select v-model="value.specification" label="Select Target Specification:" @input="specChange" />
    <div v-if="target_spec_requirements && target_spec_requirements.requirements && target_spec_requirements.requirements.length">
      <h5>Select where to generate requirements...</h5>
      <b-table
        :fields="table_fields"
        :items="target_spec_requirements.requirements"
        sticky-header
        responsive
        selectable
        select-mode="single"
        style="max-height: 50vh"
        @row-selected="rowSelected"
      >
        <template v-slot:cell(object_text)="data">
          <span v-sanitized-html="data.value" />
        </template>
      </b-table>
      <hr>
      <h5>Select Placement</h5>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio v-model="value.direction" :aria-describedby="ariaDescribedby" name="some-radios" value="before">
          Before
        </b-form-radio>
        <b-form-radio v-model="value.direction" :aria-describedby="ariaDescribedby" name="some-radios" value="after">
          After
        </b-form-radio>
        <b-form-radio v-model="value.direction" :aria-describedby="ariaDescribedby" name="some-radios" value="child">
          Child
        </b-form-radio>
      </b-form-group>
      <h3 style="text-align: center">
      <span v-if="value.dest_target && type === 'functional'">
        <strong>Action:</strong> Generate {{ type }} requirements for {{ component.context.details.name }} {{ value.direction }} {{ value.dest_target_disp }}
      </span>
        <span v-if="value.dest_target && iface">
        <strong>Action:</strong> Generate {{ type }} requirements for {{ iface.name }} {{ value.direction }} {{ value.dest_target_disp }}
      </span>
        <span v-else>
        Select Requirement For Placement
      </span>
      </h3>
    </div>
    <div v-else>
      <div id="requirements">
        Specification has no requirements.
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import SpecificationsFormSelect from '@/components/Specifications/Forms/SelectOld.vue'

export default {
  name: 'GenerateRequirementForm',
  components: {
    SpecificationsFormSelect,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    component: {
      type: Object,
      default: null,
    },
    iface: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: 'functional',
    },
  },
  computed: {
    ...mapState({
      selected_entity: state => state.domainModel.selected_entity2,
      requirements: state => state.requirements.requirements,
      performers: state => state.domainModel.performers,
      functions: state => state.domainModel.functions,
      objectives: state => state.domainModel.objectives,
      standards: state => state.domainModel.standards,
      specifications: state => state.specifications.specifications,
      target_spec_requirements: state => state.requirements.specificationRequirements,
    }),
    visible_requirements() {
      return this.requirements.filter(({ specification }) => specification === this.value.specification)
    },
    performer_opts() {
      return this.performers.map(x => ({ value: x.id, text: x.qualified_name }))
    },
    objective_opts() {
      return this.objectives.map(x => ({ value: x.id, text: x.qualified_name }))
    },
    standard_opts() {
      return this.standards.map(x => ({ value: x.id, text: x.qualified_name }))
    },
    spec_opts() {
      return this.specifications.map(x => ({ value: x.id, text: x.qualified_name }))
    },
  },
  mounted() {
    this.target_spec_requirements.requirements = []
    this.value.specification = null
    this.$store.dispatch('domainModel/getComponents')
    this.$store.dispatch('specifications/fetchSpecificationsSimple')
  },
  methods: {
    rowSelected(rows) {
      if (rows[0] && rows[0].id) {
        this.$emit('input', { ...this.value, ...{ dest_target: rows[0].id, dest_target_disp: rows[0].display_id } })
      }
    },
    specChange(spec) {
      const vueApp = this
      console.debug('Spec selected:', spec)
      vueApp.$store.dispatch('requirements/getSpecificationRequirements', spec)
        .then(() => {
          console.debug('Spec requirements: ', vueApp.target_spec_requirements)
        })
    },
  },
  data() {
    return {
      table_fields: [
        {
          key: 'section',
          label: 'Section',
        },
        {
          key: 'display_id',
          label: 'ID',
        },
        {
          key: 'object_text',
          label: 'Requirement',
        },
        {
          key: 'priority',
          label: 'Priority',
        },
      ],
    }
  },
}
</script>

<style scoped>
#requirements {
  min-height: 200px;
}
</style>
