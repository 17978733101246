<template>
  <div class="h-100 w-100" :class="{ unzoomed: zoomedOut }">
    <ontology-colour-by
      :refresh-colour-by="refreshColourBy"
      :graph="graph"
      :tree-data="treeData"
      :node-map="nodeMap"
      :focus-fn="focusNodeById"
      @onChangeColourBy="onChangeColourBy"
      @itemClicked="onClickColourKeyItem"
    />
    <div class="bn-toolbar">
      <b-dd variant="outline-secondary" size="sm" no-caret>
        <template #button-content>
          <feather-icon icon="MenuIcon"/>
        </template>
        <b-dropdown-item @click="goHome">
          Home
        </b-dropdown-item>
        <b-dropdown-item @click="showGenerateFNRequirements">
          Generate Requirements/Stories/Features
        </b-dropdown-item>
        <b-dropdown-item @click="showGenerateQARequirements">
          Generate Quality Attribute Requirements
        </b-dropdown-item>
        <b-dropdown-item @click="exportPdf">
          Export PDF
        </b-dropdown-item>
        <b-dropdown-item @click="exportCompositionUML">
          Export - UML/SysML
        </b-dropdown-item>
        <b-dropdown-item v-if="routeValue==='domain_system'" @click="exportTRLs">
          Export - TRLs
        </b-dropdown-item>
        <b-dropdown-item @click="importUml">
          Import - UML/SysML
        </b-dropdown-item>
        <b-dropdown-item @click="$bvModal.show('performer-report-modal')">
          Export Performers
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item @click="$bvModal.show('ontology-preferences-modal')">
          Preferences
        </b-dropdown-item>
      </b-dd>
      <b-button variant="outline-secondary" size="sm" title="Refresh" :disabled="isGraphLoadingStatus" @click="refreshClicked">
        <b-spinner v-if="isGraphLoadingStatus" small variant="primary" label="Loading..."/>
        <span v-else>
          <feather-icon icon="RefreshCwIcon"/>
          <span class="toolbar-text">Refresh</span>
        </span>
      </b-button>
      <portal-target
        name="ont-colour-by-dropdown-items"
        slim
      />
      <fuse-search-box
        :search-list="searchList"
        :keys="['qualified_name', 'name', 'aliases']"
        display-prop="qualified_name"
        @search="i => searchText = i"
        @resultClicked="onSearchResultClicked"
      >
        <template v-slot:default="{ option }">
          <div>
            {{ option.qualified_name }}
            <feather-icon v-if="option.icon" :icon="option.icon" size="14" />
          </div>
          <span class="text-muted"> {{ option.aliases.join(', ') }} </span>
        </template>
      </fuse-search-box>
      <b-button variant="secondary" type="button" role="button" title="Open Ontology Explorer Page"
                @click="goToTreeview">
        <span class="mr-50">Explorer</span>
        <feather-icon icon="ExternalLinkIcon"/>
      </b-button>
      <b-card no-body class="ont-breadcrumbs" v-show="breadcrumbs.length > 0">
        <b-breadcrumb>
          <b-breadcrumb-item v-for="(bc, index) in breadcrumbs" :active="index === breadcrumbs.length - 1" :key="bc"
                             @click="focusNodeById(bc)" :disabled="bc === '...'">{{
              bc === '...' ? bc : nodeMap[bc].name
            }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </b-card>
    </div>
    <div ref="canvas" :class="canvasClass"/>
    <OntologyHoverPopup
      :paper="paper"
      :scroller="scroller"
      :tree="compositionTree"
      :node-map="nodeMap"
      :tree-is-rendered="treeIsRendered"
    />
    <GenerateQualityAttributeRequirements/>
    <MakeEntityAttributeModal @attributed="focusNodeById"/>
    <ImportSubtreeModal @imported="focusNodeById"/>
    <PerformerReportModal @export="exportPerformersReport"/>
    <div v-if="selectedEntity2">
      <AddEntityModal @added="reloadFocus"/>
      <CopyEntityModal @copied="copyNodes"/>
      <DeleteEntityModal @deleted="reloadFocus"/>
      <MergeEntityModal @merged="reloadFocus" :descendants="mergeDescendants" :merge-to-node="mergeToNodeId"/>
      <MoveEntityModal @moved="moveNodes"/>
      <ImportSubtreeJSONModal @imported="reloadFocus"/>
      <GenerateRequirementsFn/>
      <AllocateFnModal/>
      <ShowComponentModal :component="selectedEntity2"/>
    </div>
    <ImportStaticUML/>
    <OntologyPreferencesModal/>
  </div>
</template>

<script>
import AddEntityModal from '@/components/Domain/Modals/AddEntity.vue'
import DeleteEntityModal from '@/components/Domain/Modals/DeleteEntity.vue'
import GenerateRequirementsFn from '@/components/Domain/Modals/GenerateRequirementsFn.vue'
import GenerateQualityAttributeRequirements from '@/components/Domain/Modals/GenerateQualityAttributeRequirements.vue'
import AllocateFnModal from '@/components/Domain/Modals/AllocateFn.vue'
import ShowComponentModal from '@/components/Domain/Modals/Context/ShowComponentModal.vue'
import axiosIns from '@/libs/axios'
import MergeEntityModal from '@/components/Domain/Modals/MergeEntityModal.vue'
import MoveEntityModal from '@/components/Domain/Modals/MoveEntityModal.vue'
import MakeEntityAttributeModal from '@/components/Domain/Modals/MakeEntityAttributeModal.vue'
import CopyEntityModal from '@/components/Domain/Modals/CopyEntityModal.vue'
import ImportSubtreeModal from '@/components/Domain/Modals/Import_Subtree.vue'
import ImportSubtreeJSONModal from '@/components/Domain/Modals/ImportSubtreeJSONModal.vue'
import ImportStaticUML from '@/components/Domain/Modals/Import_Static_UML.vue'
import PerformerReportModal from '@/components/Domain/Modals/PerformerReport.vue'
import Ripple from 'vue-ripple-directive'
import { useRouter } from '@core/utils/utils'
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api'
import store from '@/store'
import { useJointJs } from '@/components/Generic/Graph/useJointJs'
import { useJointJsTree } from '@/components/Generic/Graph/useJointJsTree'
import { createShape, updateShape } from '@/components/Domain/OntologyTreeViewJoint/ontologyShapes'
import {
  shapes,
  ui,
  util,
  layout,
} from '@clientio/rappid'
import { ContextToolbarService } from '@/components/Domain/OntologyTreeViewJoint/contextToolbarService'
import { MoveMenuService } from '@/components/Domain/OntologyTreeViewJoint/moveMenuService'
import { HaloExpandService } from '@/views/Behaviour/JointJSGraph/services/haloExpandService'
import { HaloService } from '@/views/Behaviour/JointJSGraph/services/haloService'
import FuseSearchBox from '@core/components/fuse-search-box/FuseSearchBox.vue'
import coreService from '@/libs/api-services/core-service'
import moment from 'moment/moment'
import FileSaver from 'file-saver'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OntologyColourBy from '@/components/Domain/OntologyTreeViewJoint/OntologyColourBy.vue'
import OntologyHoverPopup from '@/components/Domain/OntologyTreeViewJoint/OntologyHoverPopup.vue'
import OntologyPreferencesModal from '@/components/Domain/OntologyTreeViewJoint/OntologyPreferencesModal.vue'
import ListGroupItemEntity from '@/components/Forms/ListGroupItems/ListGroupItemEntity.vue'

export default {
  name: 'OntologyViewJoint',
  components: {
    OntologyPreferencesModal,
    OntologyHoverPopup,
    OntologyColourBy,
    FuseSearchBox,
    CopyEntityModal,
    MoveEntityModal,
    MergeEntityModal,
    MakeEntityAttributeModal,
    AddEntityModal,
    DeleteEntityModal,
    GenerateRequirementsFn,
    GenerateQualityAttributeRequirements,
    AllocateFnModal,
    ImportSubtreeModal,
    ImportSubtreeJSONModal,
    ImportStaticUML,
    PerformerReportModal,
    ShowComponentModal,
  },
  /*
  * The Ontology view (Joint) is also used by
  * FunctionAllocation (Functional Analysis)
  * and System Decomposition
  * - Has a focus on performance for large trees (1000+ nodes)
  * - Initially loads only abstract nodes from Model_Lookup
  * - Client-side calculation of descendant counts where possible
  * - DB-first search
  * Component startup is through onMounted, which then does initial focus on a node (if necessary, by route)
  * There are route watchers for focus param
  *  Root vs focus:
  *     Root defines the top node for the tree  (see reRootOnNode)
  *       - e.g. for functional analysis this defaults to Functions abstract node from model_lookup
  *     Focus node (query string parameter) highlights, zooms to, and selects the node for sidebar
  *     Clicking a node silently updates the route to avoid an infinite loop through the watchers
  */
  setup(props, context) {
    const HEADER_NODE_PAGE_SIZE = 50 // how many children should "header" nodes group under them?
    const HIDE_EXPAND_HANDLES_PAST_ZOOM = 0.3 // the threshold past which to hide the arrow expand handles when zooming out
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)
    const routeValue = computed(() => route.value.name)
    const routeQuery = computed(() => route.value.query)
    const canvas = ref(null) // Element ref
    const canvasClass = ref('canvas ont-node')
    const addNodeType = ref('Event')
    const isGraphLoadingStatus = ref(true)
    const treeIsRendered = ref(false)
    const hiddenNodeIds = ref([])
    const breadcrumbs = ref([])
    const compositionTree = ref({})
    const nodes = ref([])
    // searchList combines already loaded nodes with the results of a db search query
    const searchList = computed(() => store.state.domainModel.components)
    const searchText = ref('')
    const zoomLevel = ref(1) // read-only
    const zoomedOut = computed(() => zoomLevel.value < HIDE_EXPAND_HANDLES_PAST_ZOOM)
    const refreshColourBy = ref(false)
    const isKeyVisible = ref(true)
    const mergeDescendants = ref([])
    const mergeToNodeId = ref('')

    const links = ref([])
    const {
      graph, paper, nav, scroller, selection, keyboard, bulkSelectedNodes: selectedNodes, graphLayout, exporters,
    } = useJointJs(canvas)

    const treeOptions = {
      nodeTransformFn: transformNode,
      nodeFilterFn: n => !n.labels.includes('View') || !n.labels,
      nodeHideFn: n => initialLoad && n._children.length < 1,
      createShape,
      addLink,
      pageSize: HEADER_NODE_PAGE_SIZE,
    }
    const {
      nodeMap, treeData, showAllAncestors, afterLoadTree, createTree, refreshTree, redrawNodes,
    } = useJointJsTree(treeOptions)
    const haloService = new HaloService()
    const selectedEntity = computed(() => (selectedNodes.value?.length > 0 ? selectedNodes.value[0] : null))
    const selectedEntity2 = computed(() => store.state.domainModel.selected_entity2)
    const treeLayoutView = new ui.TreeLayoutView({
      paper,
      model: graphLayout,
      validateConnection: (element, candidateParent) => !!candidateParent,
      validatePosition: () => false,
      reconnectElements: (elements, parent, evt) => {
        handleDragDropNodes(elements, parent)
      },
    })

    keyboard.on('ctrl+/', evt => {
      evt.preventDefault()
      nextTick(() => {
        try {
          const ele = document.querySelector('#bn-search-dropdown .vs__search')
          ele.select()
          ele.focus()
          // throws exceptions even though it works
          // eslint-disable-next-line no-empty
        } catch (e) {
          console.error(e)
        }
      })
    })

    // Popup a menu when dragging a node and dropping using
    // the jointJs TreeLayoutView tool (big dots show where to move)
    function handleDragDropNodes(elements, parent) {
      // elements is an array because of multiselect
      const dragElement = elements[0]
      if (!dragElement) return
      const moveMenuService = new MoveMenuService()
      const { x, y } = paper.localToClientPoint(parent.position())
      const menu = moveMenuService.createMoveMenu(dragElement, x, y)
      menu.on('action:moveAgg', async () => {
        isGraphLoadingStatus.value = true
        menu.remove()
        await moveNodes(dragElement.id, parent.id, false)
      })
      menu.on('action:moveSub', async () => {
        isGraphLoadingStatus.value = true
        menu.remove()
        await moveNodes(dragElement.id, parent.id, true)
      })
      menu.on('action:copyAgg', async () => {
        isGraphLoadingStatus.value = true
        menu.remove()
        await copyNodes(dragElement.id, parent.id, false)
      })
      menu.on('action:copySub', async () => {
        isGraphLoadingStatus.value = true
        menu.remove()
        await copyNodes(dragElement.id, parent.id, true)
      })
      menu.on('action:merge', async () => {
        menu.remove()
        const nodeIsSelected = dragElement.id === store.state.domainModel.selected_entity2?.context?.details.id
        if (!nodeIsSelected) await focusNodeById(dragElement.id)
        showMergeModal(dragElement.id, parent.id)
      })
    }

    async function moveNodes(id, parentId, isInheritance) {
      await store.dispatch('domainModel/moveComponent', {
        id,
        parent_id: parentId,
        parent_rel_type: isInheritance ? 'inheritance' : 'aggregation',
      })
      await reloadGraph()
      await focusNodeById(id)
    }

    // In place refresh of the view of a single node when edited
    function updateNode(entity) {
      const id = entity?.context?.details?.id
      const cell = graph.getCell(id)
      if (!cell) {
        console.warn('cell not found')
        return
      }
      entity.context.details.labels = entity.context.labels
      const node = transformNode(entity.context.details)
      updateShape(node, cell, nodeMap[node.pid])
    }

    watch(
      () => selectedEntity2.value,
      (newNode, oldNode) => {
        if (newNode && newNode?.context?.details) {
          updateNode(newNode)
        }
      },
    )

    async function copyNodes(id, parentId, isInheritance) {
      const data = await store.dispatch('domainModel/copyComponent', {
        cpt: id,
        parent: parentId,
        parent_rel_type: isInheritance ? 'inheritance' : 'aggregation',
      })
      await reloadGraph()
      await focusNodeById(data.id)
    }

    function transformNode(rawNode) {
      const x = rawNode
      x.type = rawNode.type || 'Unknown'
      if (x.labels) {
        x.type = x.labels.filter(l => l !== 'Component' && !l.startsWith('RBAC_')).join()
      }
      x.tags = []
      if (x.abstract) {
        x.nodeType = 'abstract'
        x.tags.push('abstract')
      } else {
        x.nodeType = 'node'
        x.tags.push('not_abstract')
      }
      x.tags.push(x.validity)
      if (x.labels && (x.labels.includes('Function') || x.labels.includes('Capability'))) {
        x.tags.push('fn')
      } else {
        x.tags.push('nfn')
      }
      if (x.parent_rel && x.parent_rel === 'inheritance') {
        x.link_label = 'sub-type'
      } else {
        x.link_label = x.multiplicity
      }
      x.display_name = x.acronym && x.acronym !== '' ? `${x.qualified_name} (${x.acronym})` : x.qualified_name
      return x
    }

    function addLink(source, target) {
      const link = new shapes.standard.Link({ source, target })
      link.appendLabel({
        attrs: {
          text: {
            text: target.link_label || target.multiplicity,
          },
        },
        position: {
          distance: -32,
        },
      })
      return link
    }

    let initialLoad = true

    async function createGraph() {
      await createTree(initialLoad)
    }

    /// Load the tree data from API and (re-)create the nodeMap and ancestors
    async function loadTree() {
      isGraphLoadingStatus.value = true
      let currentRoot = route.value.params.root
      if (['domain_function', 'domain_analysis'].includes(route.value.name)) {
        currentRoot = store.state.model.lookup.Functions
      } else if (['domain_system', 'domain_system_focus'].includes(route.value.name)) {
        currentRoot = store.state.model.lookup.Performers
      }
      await store.dispatch('domainModel/getCompTreeData', currentRoot || null)
      compositionTree.value = store.state.domainModel.composition_tree
      await store.dispatch('domainModel/getComponents')
      isGraphLoadingStatus.value = false
      await focusOnRouteNode(routeQuery.value.focus)
      compositionTree.value.root = currentRoot || compositionTree.value.root
      afterLoadTree(compositionTree.value)
      await createGraph()
    }

    /// Assuming the tree data is already loaded, re-create visible shapes & perform layout
    async function redrawGraph() {
      isGraphLoadingStatus.value = true
      console.time('redrawNodes')
      const allCells = await redrawNodes()
      console.timeEnd('redrawNodes')
      console.time('resetCells')
      paper.freeze()
      graph.resetCells(allCells)
      console.timeEnd('resetCells')
      try {
        allCells[0].set({
          position: { x: 200, y: 200 },
          size: { width: 300, height: 100 },
          margin: 20,
          nextSiblingGap: 20,
          offset: -200,
        })
      } catch (e) {
        console.log('Couldn\'t layout, no unhidden cells loaded')
      }
      console.time('graphLayout')
      graphLayout.layout()
      refreshColourBy.value = !refreshColourBy.value
      paper.unfreeze()
      if (allCells.length < 10000) {
        const paperDimensions = paper.getContentBBox({ useModelGeometry: true })
        const ratio = paperDimensions.width / paperDimensions.height
        const threshold = 0.1
        if ((ratio > threshold && ratio < 1 - threshold) || (1 / ratio > threshold && 1 / ratio < 1 - threshold)) {
          nav()
        }
      }
      console.timeEnd('graphLayout')
      // RAF cos the bbox of the node isn't filled til after render
      console.time('focusOnRouteNode')
      requestAnimationFrame(redrawExpandHalos)
      await focusOnRouteNode()
      console.timeEnd('focusOnRouteNode')
      isGraphLoadingStatus.value = false
      initialLoad = false
      await nextTick()
      treeIsRendered.value = true
    }

    /// Does a loadTree then a redraw
    async function reloadGraph() {
      await loadTree()
      await redrawGraph()
    }

    async function refreshClicked() {
      await reloadGraph()
    }

    onMounted(async () => {
      // do we need to append here if the useJointJs is already doing this?
      // ...or is this an override?
      // canvas.value.appendChild(scroller.el)
      await reloadGraph()
      scroller.center()
      paper.unfreeze()
      paper.scale(0.85)
      await focusOnRouteNode(route.value.params.focus)
    })

    // id: a node's UUID
    function isHeader(id) {
      return !!nodeMap[id]?.isHeader
    }

    function focusOnHeader(id) {
      const element = graph.getCell(id)
      if (element) {
        haloService.createHalo(element, paper, graph, null, onHideChildren, nodeMap[id]._descendantCount)
      }
    }

    scroller.on('scroll', () => {
      zoomLevel.value = scroller.zoom()
    })
    paper.on('cell:pointerclick', (cellView, evt, x, y) => {
      if (cellView.model.isLink()) return
      if (isHeader(cellView.model.id)) {
        focusOnHeader(cellView.model.id)
        return
      }
      focusNodeById(cellView.model.id, false)
      updateUrlFocus(cellView.model.id)
      context.emit('sidebar', true)
    })
    paper.on('blank:pointerup', evt => {
      // If any nodes were selected using the drag
      if (selection.collection.models.length > 0 && evt.originalEvent.ctrlKey) {
        if (!evt.shiftKey) {
          selectedNodes.value = []
        }
        selection.collection.models.forEach(cell => {
          selectedNodes.value.push(cell.id)
        })
        selectedNodes.value = [...new Set(selectedNodes.value)]
        if (evt.shiftKey) {
          // UNION mode (add bulk to existing bulk selection)
          selection.collection.reset(graph.getElements().filter(e => selectedNodes.value.includes(e.id)))
        }
        if (selection.collection.models.length === 1) {
          context.emit('sidebar', true)
        }
      }
    })
    paper.on('cell:pointerdown', (elementView, evt) => {
      if (elementView.model.isLink()) return
      treeLayoutView.startDragging([elementView.model])
    })

    const haloExpandService = new HaloExpandService()

    async function onHideChildren(element) {
      // toggle all children's visibility
      console.time('onHideChildren')
      const children = nodeMap[element.id]._children
      const firstChild = children[0]
      if (firstChild) {
        // hide this node's children
        children.forEach(cid => {
          nodeMap[cid]._hidden = true
        })
        const combined = [...hiddenNodeIds.value, ...children]
        hiddenNodeIds.value = [...new Set(combined)]
        await redrawGraph()
      }
      await focusNodeById(element.id)
      console.timeEnd('onHideChildren')
    }

    async function onShowChildren(element) {
      // toggle all children's visibility
      console.time('onShowChildren')
      const children = nodeMap[element.id]._children
      const firstChild = children[0]
      if (firstChild) {
        // show this node's children
        children.forEach(cid => {
          nodeMap[cid]._hidden = false
        })
        hiddenNodeIds.value = hiddenNodeIds.value.filter(id => !children.includes(id))
        await redrawGraph()
      }
      // await focusNodeById(element.id)
      console.timeEnd('onShowChildren')
    }

    function redrawExpandHalos() {
      console.time('redrawExpandHalos')
      graph.getElements().forEach(ele => {
        if (nodeMap[ele.id] && nodeMap[ele.id]._children.filter(cId => nodeMap[cId]._hidden).length > 0) {
          haloExpandService.createHalo(ele, paper, graph, onShowChildren, nodeMap[ele.id]._descendantCount)
        }
      })
      console.timeEnd('redrawExpandHalos')
    }

    const contextToolbarService = new ContextToolbarService()
    let parentElement
    // Context menu event
    paper.on('cell:contextmenu', triggerContextMenu)

    function triggerContextMenu(cellView, evt) {
      if (cellView.model.isLink()) return
      if (isHeader(cellView.model.id)) return
      // eslint-disable-next-line prefer-destructuring
      if (!graph.isSource(cellView.model)) parentElement = graph.getPredecessors(cellView.model)[0]
      else parentElement = null
      // Render the menu
      const contextToolbar = contextToolbarService.createContextMenu(cellView, parentElement, evt.clientX, evt.clientY)
      handleContextActions(contextToolbar)
      document.querySelector('.joint-context-toolbar').addEventListener('contextmenu', evt => {
        // Because JointJS only prevents default on paper elements, it doesn't apply to the context menu that happens
        // to be right under your mouse when you right-click, which just sets off the default event :)
        evt.preventDefault()
      })
    }

    onUnmounted(() => {
      keyboard.off('ctrl+/')
      keyboard.off('ctrl+d')
      keyboard.off('ctrl+a')
      ui.Popup.close()
    })

    function handleContextActions(contextToolbar) {
      const node = contextToolbarService.contextElement
      const nodeIsSelected = node.model.id === store.state.domainModel.selected_entity2?.context?.details.id
      contextToolbar.on('action:addChild', async evt => {
        contextToolbar.remove()
        if (!nodeIsSelected) await focusNodeById(node.model.id)
        context.root.$bvModal.show('add-entity-modal')
      })
      contextToolbar.on('action:delete', async evt => {
        contextToolbar.remove()
        if (!nodeIsSelected) await focusNodeById(node.model.id)
        if (Object.values(store.state.model.lookup).find(v => v === node.model.id)) {
          await context.root.$bvModal.msgBoxOk('You can\'t delete the model lookup nodes', { centered: true })
        } else {
          context.root.$bvModal.show('delete_entity')
        }
      })
      contextToolbar.on('action:addInstance', async evt => {
        contextToolbar.remove()
        if (!nodeIsSelected) await focusNodeById(node.model.id)
        context.root.$bvModal.show('create-instance-modal')
      })
      contextToolbar.on('action:merge', async evt => {
        contextToolbar.remove()
        isGraphLoadingStatus.value = true
        if (!nodeIsSelected) await focusNodeById(node.model.id)
        showMergeModal(node.model.id)
        isGraphLoadingStatus.value = false
      })
      contextToolbar.on('action:move', async evt => {
        contextToolbar.remove()
        if (!nodeIsSelected) await focusNodeById(node.model.id)
        context.root.$bvModal.show('move-entity-modal')
      })
      contextToolbar.on('action:copy', async evt => {
        contextToolbar.remove()
        if (!nodeIsSelected) await focusNodeById(node.model.id)
        context.root.$bvModal.show('copy-entity-modal')
      })
      contextToolbar.on('action:makeAttr', async evt => {
        contextToolbar.remove()
        if (!nodeIsSelected) await focusNodeById(node.model.id)
        context.root.$bvModal.show('make-entity-attr-modal')
      })
      contextToolbar.on('action:levelUp', async evt => {
        contextToolbar.remove()
        let parentId = nodeMap[node.model.id].pid
        if (!parentId) {
          const result = await coreService.componentApi.getComponentParent(node.model.id, route.value.params.modelId)
          parentId = result ? result.id : null
        }
        await reRootOnNode(parentId)
      })
      contextToolbar.on('action:showOnly', async evt => {
        contextToolbar.remove()
        await reRootOnNode(node.model.id)
      })
      contextToolbar.on('action:classDiagram', async evt => {
        contextToolbar.remove()
        goToClassDiagram(node.model.id)
      })
      contextToolbar.on('action:importSubtree', async evt => {
        contextToolbar.remove()
        if (!nodeIsSelected) await focusNodeById(node.model.id)
        context.root.$bvModal.show('import-subtree-json-modal')
      })
      contextToolbar.on('action:exportSubtree', async evt => {
        contextToolbar.remove()
        if (!nodeIsSelected) await focusNodeById(node.model.id)
        exportSubtree(node.model.id)
      })
      contextToolbar.on('action:generateQA', async evt => {
        contextToolbar.remove()
        if (!nodeIsSelected) await focusNodeById(node.model.id)
        context.root.$bvModal.show('generate-qa-requirements-modal')
      })
      contextToolbar.on('action:generateFN', async evt => {
        contextToolbar.remove()
        if (!nodeIsSelected) await focusNodeById(node.model.id)
        context.root.$bvModal.show('generate-fn-requirements-modal')
      })
    }

    async function showMergeModal(nodeId, mergeToId) {
      function _getChildren(n, result) {
        const c = nodeMap[n]._children
        result.push(...c)
        c.forEach(cn => _getChildren(cn, result))
      }
      const result = []
      _getChildren(nodeId, result)
      mergeDescendants.value = result
      mergeToNodeId.value = mergeToId
      context.root.$bvModal.show('merge-entity-modal')
    }

    async function reRootOnNode(nodeId) {
      await store.dispatch('domainModel/getCompTreeData', nodeId)
      compositionTree.value = store.state.domainModel.composition_tree
      afterLoadTree(compositionTree.value)
      await createGraph()
      await redrawGraph()
      await focusNodeById(nodeId)
      updateUrlFocus(nodeId)
    }

    /// Update the focus in the URL silently, should not actually focus the node
    function updateUrlFocus(id) {
      let suffix = ''
      if (!route.value.name.endsWith('_focus')) {
        suffix = '_focus'
      }
      const { href } = router.resolve({
        name: `${route.value.name}${suffix}`,
        params: { ...routeParams.value, focus: id },
      })
      routeParams.value.focus = id
      window.history.pushState({}, null, href)
    }

    /// If the result has an icon, it needs to be loaded from the db, using reRoot
    async function onSearchResultClicked(result) {
      if (result.icon) {
        reRootOnNode(result.id)
      } else {
        const [_, isUnhidden] = await showAllAncestors(result.id)
        if (isUnhidden) {
          await redrawGraph()
        }
        updateUrlFocus(result.id)
        focusNodeById(result.id)
      }
    }

    watch(
      () => routeParams.value,
      newParams => {
        if (compositionTree.value.length < 1) {
          reloadGraph()
        }
        focusOnRouteNode(routeParams.value.focus)
      },
    )

    watch(
      () => routeQuery.value,
      newParams => {
        if (compositionTree.value.length < 1) {
          reloadGraph()
        }
        focusOnRouteNode(routeQuery.value.focus)
      },
    )

    async function focusOnRouteNode(nodeId) {
      if (nodeId) {
        await focusNodeById(nodeId)
      }
    }

    function rebuildBreadcrumbs(ancestors) {
      breadcrumbs.value = ancestors.filter(n => !nodeMap[n].isHeader).reverse()
      if (breadcrumbs.value.length > 5) {
        // provide a shorter version of the breadcrumbs
        breadcrumbs.value = [
          breadcrumbs.value[1],
          '...',
          breadcrumbs.value[breadcrumbs.value.length - 3],
          breadcrumbs.value[breadcrumbs.value.length - 2],
          breadcrumbs.value[breadcrumbs.value.length - 1],
        ]
      }
    }

    async function focusNodeById(id, zoomTo = true) {
      if (isHeader(id)) {
        focusOnHeader(id)
        return
      }
      const [ancestors, isUnhidden] = await showAllAncestors(id)
      if (isUnhidden) {
        await redrawGraph()
      }
      const element = graph.getCell(id)
      if (element) {
        if (id !== selectedEntity2.value.context?.details.id) {
          await store.dispatch('domainModel/selectEntity2', id)
        }
        selection.collection.reset([element])
        selectedNodes.value = [element]
        rebuildBreadcrumbs(ancestors)
        haloService.createHalo(element, paper, graph, null, onHideChildren, nodeMap[element.id]._descendantCount)
        if (zoomTo) {
          scroller.scrollToElement(element, { animation: { duration: 600 } })
        }
      }
    }

    function showGenerateFNRequirements() {
      context.root.$bvModal.show('generate-fn-requirements-modal')
    }

    function showGenerateQARequirements() {
      context.root.$bvModal.show('generate-qa-requirements-modal')
    }

    async function goHome() {
      route.value.params.root = null
      await loadTree()
      await redrawGraph()
    }

    function importUml() {
      context.root.$bvModal.show('import-uml-modal')
    }

    function colourBy(value) {
      canvasClass.value = `canvas ont-${value}`
      refreshColourBy.value = value
    }

    async function reloadFocus(targetNodeId) {
      await reloadGraph()
      await focusNodeById(targetNodeId)
    }

    function goToTreeview() {
      router.push({ name: 'ontology_treeview' })
    }

    function goToClassDiagram(nodeId) {
      router.push({ name: 'ontology_class_diagram_root', params: { root: nodeId }, query: { focus: nodeId } })
    }

    function exportPerformersReport() {
      const { modelId } = router.currentRoute.params
      const modelName = store.state.model.name
      const params = {
        model: modelId,
      }
      // Use datetime to make the file unique
      let now = new Date()
      now = moment(now).format('MMM_DD_HH_MM_SS')
      const filename = `performer_report_${modelName}_${now}.xlsx`
      isGraphLoadingStatus.value = true
      axiosIns.post('/api/v2/domain_model/export_performers_report', {
        model: modelId,
      }, { params, responseType: 'blob' })
        .then(({ data }) => {
          FileSaver.saveAs(data, filename)
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred while exporting the performers report',
              text: `${error}`,
              icon: 'AlertIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { isGraphLoadingStatus.value = false })
    }

    function exportCompositionUML() {
      const { modelId } = router.currentRoute.params
      const params = {
        model: modelId,
      }
      // Use datetime to make the file unique
      let now = new Date()
      now = moment(now).format('MMM_DD_HH_MM_SS')
      const filename = `ontology_export_${now}.xmi`
      isGraphLoadingStatus.value = true
      axiosIns.post('/api/v2/domain_model/export_uml', {
        root: route.value.path.root,
        model: modelId,
      }, { params, responseType: 'blob' })
        .then(({ data }) => {
          FileSaver.saveAs(data, filename)
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred while exporting to UML',
              text: `${error}`,
              icon: 'AlertIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { isGraphLoadingStatus.value = false })
    }

    function exportTRLs() {
      // Compose the filename
      let now = new Date()
      now = moment(now).format('MMM_DD_HH_MM_SS')
      const filename = `ontology_TRL_export_${now}.csv`

      // Send the request
      const { modelId } = router.currentRoute.params
      const config = {
        params: { model: modelId },
        responseType: 'blob',
      }
      const data = {
        root: store.state.model.lookup.Performers,
        model: modelId,
      }

      isGraphLoadingStatus.value = true
      axiosIns.post('/api/v2/domain_model/export_trl', data, config)
        .then(({ data }) => {
          FileSaver.saveAs(data, filename)
        })
        .catch(error => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred while exporting TRLs',
              text: `${error}`,
              icon: 'AlertIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => { isGraphLoadingStatus.value = false })
    }

    function exportSubtree(nodeId) {
      const modelId = store.state.model.id
      const params = {
        model: modelId,
      }
      let now = new Date()
      now = moment(now).format('MMM_DD_HH_MM_SS')
      const filename = `ontology_subtree_export_${now}.json`
      axiosIns.post(
        '/api/v2/domain_model/export_subtree',
        { root: nodeId, filename },
        { params, responseType: 'blob' },
      ).then(({ data }) => {
        // save file
        FileSaver.saveAs(data, filename)
        context.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Exported ontology subtree to JSON',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(response => {
        console.error(response.data)
        context.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to export ontology subtree',
            text: `${response.data}`,
            icon: 'AlertIcon',
            variant: 'danger',
          },
        })
      })
    }

    function onChangeColourBy(className) {
      canvasClass.value = `canvas ${className}`
    }

    function onClickColourKeyItem(keyName) {
      console.log(keyName)
    }

    return {
      treeIsRendered,
      mergeDescendants,
      mergeToNodeId,
      goToTreeview,
      goToClassDiagram,
      chart: false,
      canvas,
      paper,
      scroller,
      compositionTree,
      canvasClass,
      selectedEntity,
      selectedEntity2,
      dragNode: '',
      dropNode: '',
      selParent: '',
      selNode: '',
      nodes,
      newNode: {},
      layoutIcon: '',
      nodeMap,
      onChangeColourBy,
      focusNodeById,
      onSearchResultClicked,
      refreshClicked,
      isGraphLoadingStatus,
      reRootOnNode,
      routeValue,
      showGenerateQARequirements,
      showGenerateFNRequirements,
      copyNodes,
      moveNodes,
      searchList,
      searchText,
      goHome,
      exportPdf: exporters.pdf,
      importUml,
      exportCompositionUML,
      exportTRLs,
      reloadFocus,
      exportPerformersReport,
      breadcrumbs,
      zoomedOut,
      refreshColourBy,
      isKeyVisible,
      colourBy,
      onClickColourKeyItem,
      graph,
      treeData,
    }
  },
  directives: {
    Ripple,
  },
  props: {
    updateObject: {
      type: Object,
      default: null,
    },
  },
}

</script>

<style scoped lang="scss">
@import "~@clientio/rappid/rappid.css";
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu.scss';

body {
  height: 60vh;
  box-sizing: border-box;
  margin: 0;

  .content-wrapper {
    .content-body {
      width: 100%;
      height: 100%;
    }
  }

  .canvas {
    width: 100%;
    height: 100%;

    .joint-paper {
      border: 1px solid #000000;
    }
  }

  .toolbar-text {
    display: none;
  }
}

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu';
@import 'scss/buildconfig.scss';
@import 'scss/nodetypes.scss';

.ontxt small {

}

.ontxt {
  height: 100%;
  padding: 0.1rem 0.1rem;
  line-height: 0;

  small {
    overflow: hidden;
    width: calc(300px - 2rem);
    display: inline-block;
    height: 2rem;
    position: relative;
    top: 0;
    white-space: nowrap;
    word-break: break-all;
    text-overflow: ellipsis;
    line-height: 1rem;
  }
  p {
    line-height: normal;
    font-weight: bold;
    display: block;
    position: relative;
    bottom: 1rem;
    text-align: center;
  }
}

.unzoomed .handles {
  display: none;
}

.focused rect {
  fill: #16FFFF;
  stroke-width: 2;
}

.link-tools {
  display: none
}

.selection-box {
  pointer-events: none;
}

.joint-navigator.joint-theme-default {
  top: -200px;
  background-color: rgba(125, 125, 125, 0.3);
  border: none;
  border-top: 2px solid rgba(125, 125, 125, 0.4);
  border-right: 2px solid rgba(125, 125, 125, 0.4);
  border-bottom: 2px solid rgba(125, 125, 125, 0.4);
  border-top-right-radius: 0.428rem;
  border-bottom-right-radius: 0.428rem;
}

.bn-toolbar {
  position: absolute;
  display: flex;
  align-items: normal;
  gap: 0.5rem !important;
  left: 2rem !important;
  z-index: 1;
  height: 2.75rem;
}

.collapse-nodes {
  position: absolute;
  padding: 0.5rem;
  font-weight: bold;
  height: 2.5rem;
  background: black;
  border: 1px solid black;
  border-radius: 1rem;
  left: -0.5rem;
}

.joint-selection.joint-theme-default .selection-wrapper {
  border: 3px dotted #a7a7a7;
}

.joint-halo.joint-theme-default .handle {
  filter: invert(0.9) drop-shadow(1px 1px 2px #222);
}

.joint-selection.joint-theme-default .selection-box {
  border: 3px solid #F8A63B;
}

.joint-type-standard.joint-theme-default.joint-link {
  filter: invert(1) contrast(0.5);
}

.joint-paper.joint-theme-default {
  background-color: rgba(0, 0, 0, 0);
}

.ont-node ~ text {
  font-weight: bolder;
  font-size: 1rem;
}

.joint-theme-default foreignObject {
  fill: black !important;
  color: black !important;
  text-shadow: 1px 1px 3px white;
}

.joint-theme-default text {
  fill: black !important;
  text-shadow: 1px 1px 3px white;
}

.search-input {
  border-radius: 4px;
  width: 320px;
  background: $body-bg;

  > div {
    height: 2.75rem;
  }
}

.bn-toolbar {
  background: $body-bg;
}

.joint-popup.joint-theme-default {
  background-color: $body-bg;
}

body.dark-layout ~ .joint-context-toolbar.joint-theme-default {
  box-shadow: 0.1rem 0.1rem 1rem black;
  background-color: $theme-dark-body-bg;
  border-color: $theme-dark-border-color;

  .tool {
    background-color: $theme-dark-body-bg;
    border-color: $theme-dark-border-color;
    color: $theme-dark-body-color;
    text-align: left;
    font-family: "Montserrat", Helvetica, Arial, serif;
    font-size: 1rem;
    padding: 1rem;
  }

  .tool:hover {
    background-color: $theme-dark-body-color;
    color: $theme-dark-body-bg;
  }
}

body.dark-layout {
  .labels.joint-link {
    rect {
      fill: #1e1e1edf;
    }
  }
  .search-input {
    background: $theme-dark-body-bg;
  }

  .bn-toolbar {
    background: $theme-dark-body-bg;
  }

  .btn-outline-secondary {
    color: #aab0c6;
    border-color: #9ba5b3;
  }

  .joint-theme-default foreignObject {
    fill: lightgrey !important;
    color: lightgrey !important;
    text-shadow: 1px 1px 3px black;
  }

  .joint-theme-default text {
    fill: lightgrey !important;
    text-shadow: 1px 1px 2px black;
  }
}

.joint-context-toolbar.joint-theme-default.joint-vertical .tool:not(:last-child) {
  border-bottom: none;
}

.joint-context-toolbar.joint-theme-default {
  box-shadow: 1rem 1rem 2rem grey;

  .tool {
    background: #f4f4f4;
    text-align: left;
    font-family: "Montserrat", Helvetica, Arial, serif;
    font-size: 1rem;
    padding: 1rem;
  }
}

.ont-breadcrumbs {
  position: absolute;
  top: 4rem;
  left: 0;
  z-index: 1;
  padding: 0.3rem;
}
</style>
