<template>
  <b-modal
    id="ontology-preferences-modal"
    title="Ontology Preferences"
    size="md"
    lazy
    no-close-on-backdrop
    @show="onShow"
    @ok="onSubmit"
  >
    <h5 class="text-primary mb-1">Entity popup settings</h5>
    <div class="d-inline-flex w-100 justify-content-between px-1">
      <b-form-group
        label="Show popup on hover"
        title="Enable/disable the popup from showing when you hover over an entity"
        class="w-100"
      >
        <b-form-checkbox switch v-model="showPopup" />
      </b-form-group>
      <b-form-group
        label="Popup delay (ms)"
        title="Adjust the delay in milliseconds that defines when the popup will show"
        class="w-100 mr-2"
      >
        <b-form-input
          v-model="popupDelayMs"
          type="number"
          min="0"
          :debounce="500"
        />
      </b-form-group>
    </div>

    <template #modal-footer="{ ok }">
      <b-button variant="primary" @click="ok">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script setup>
import { computed } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'OntologyPreferencesModal',
  setup(props, context) {
    const showPopup = computed({
      get() {
        return store.state.preferences.ontologyPopupShow
      },
      set(newValue) {
        store.dispatch('preferences/ontologyPopupShow', newValue)
      },
    })
    const popupDelayMs = computed({
      get() {
        return store.state.preferences.ontologyPopupDelayMs
      },
      set(newValue) {
        store.dispatch('preferences/ontologyPopupDelayMs', newValue)
      },
    })

    const onShow = () => { store.dispatch('preferences/loadAll') }
    const onSubmit = () => {}

    return {
      onShow,
      onSubmit,
      popupDelayMs,
      showPopup,
    }
  },
}
</script>

<style lang="scss">
</style>
