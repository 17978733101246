<template>
  <b-button
    v-b-tooltip.hover.v-info
    :title="tooltip"
    :variant="variant"
    :size="size"
    class="tipTapButton"
    :class="classes"
    tabindex="-1"
    :disabled="disabled"
    @click="onClick"
  >
    <slot />
  </b-button>
</template>

<script>
export default {
  name: 'TipTapMenuButton',
  props: {
    tooltip: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'flat-secondary',
    },
    size: {
      type: String,
      default: 'sm',
    },
    classes: {
      type: String,
      default: 'btn-icon',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick(evt) {
      evt.preventDefault()
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/@core/scss/base/colors.scss';

.is-active {
  color: $primary;
}

.tipTapButton:hover {
  color: black;
}

.dark-layout {
  .tipTapButton:hover {
    color: white;
  }
}
</style>
