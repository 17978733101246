import axiosIns from '@/libs/axios'
import router from '@/router'

const getDefaultState = () => ({
  behaviours: [],
  behaviourViews: [],
  selectedBehaviourTree: null,
  selectedBehaviourNode: null,
  selectedConfigs: [],
  closedIssues: [],
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_BEHAVIOUR_TREES: (state, bts) => { state.behaviourViews = bts },
    SET_SELECTED_BEHAVIOUR_TREE: (state, bt) => { state.selectedBehaviourTree = bt },
    SET_SELECTED_BEHAVIOUR_NODE: (state, node) => { state.selectedBehaviourNode = node },
    CLEAR_SELECTED_NODE: state => { state.selectedBehaviourNode = {} },
    CLEAR_SELECTED_CONFIGS: state => { state.selectedConfigs = [] },
    CLEAR_SELECTED_TREE: state => {
      state.selectedBehaviourTree = {}
      state.selectedBehaviourNode = {}
    },
    CLEAR_ALL: state => { Object.assign(state, getDefaultState()) },
    SET_CONFIGS: (state, configs) => { state.selectedConfigs = configs },
    SET_CLOSED_ISSUES: (state, issues) => { state.closedIssues = issues },
  },
  actions: {
    getAllBehaviourTrees: ({ commit }) => new Promise((resolve, reject) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'Model-Id': modelId } }
      return axiosIns
        .get('/api/v2/behaviour', config)
        .then(({ data }) => {
          commit('SET_BEHAVIOUR_TREES', data)
          resolve()
        })
        .catch(error => {
          console.error(error)
          reject(error)
        })
    }),
    selectBehaviourTree: ({ commit }, behaviourTreeId) => {
      const { modelId } = router.currentRoute.params
      const config = { headers: { 'Model-Id': modelId } }
      if (behaviourTreeId) {
        return axiosIns
          .get(`/api/v2/behaviour/get_bt/${behaviourTreeId}`, config)
          .then(({ data }) => {
            commit('SET_SELECTED_BEHAVIOUR_TREE', data)
            return data
          })
          .catch(e => console.error(e))
      }
      commit('SET_SELECTED_BEHAVIOUR_TREE', false)
      return null
    },
    selectBehaviourNode: ({ commit, state }, behaviourNodeId) => {
      const { modelId } = router.currentRoute.params
      const behaviourTreeId = state.selectedBehaviourTree.id || null
      // Don't filter the tests by configs
      const params = { model: modelId, configs: { filter: [] } }
      return axiosIns
        .get(`/api/v2/behaviour/get_behaviour_context/${behaviourTreeId}/${behaviourNodeId}`, { params })
        .then(({ data }) => commit('SET_SELECTED_BEHAVIOUR_NODE', data))
        .catch(e => console.error(e))
    },
    getClosedIssues: ({ commit, state }) => {
      const { modelId } = router.currentRoute.params
      const behaviourNodeId = state.selectedBehaviourNode.details.id || null
      return axiosIns
        .get(`/api/v2/behaviour/get_behaviour_closed_and_withdrawn_issues/${behaviourNodeId}`)
        .then(({ data }) => commit('SET_CLOSED_ISSUES', data))
        .catch(e => console.error(e))
    },
    clearSelectedBehaviourTree: ({ commit }) => { commit('CLEAR_SELECTED_TREE') },
    clearSelectedBehaviourNode: ({ commit }) => { commit('CLEAR_SELECTED_NODE') },
    clearSelectedConfigs: ({ commit }) => { commit('CLEAR_SELECTED_CONFIGS') },
    clearBehaviours: ({ commit }) => { commit('CLEAR_ALL') },
    selectConfigs: ({ commit }, configs) => {
      commit('SET_CONFIGS', configs)
    },
  },
}
