<template>
<div>
  <p class="mt-0 mb-25 font-weight-bold text-primary">
    Technology Readiness Level
    <span class="font-small-2">(TRL)</span>
  </p>

  <div v-if="trl || trl !== 0" class="ml-50">
    <b-badge :class="resolveClass(trl)">
      {{ resolveTestReadinessLevel(trl).name }}
    </b-badge>
    <span class="font-small-2 font-weight-bold">
      : {{ resolveTestReadinessLevel(trl).description }}
    </span>
  </div>
  <div v-else class="ml-50">
    <b-badge class="trl0">
      Unevaluated
    </b-badge>
    <span class="font-small-2 font-weight-bold">
      : no data available
    </span>
  </div>
</div>
</template>

<script>
import store from '@/store'

export default {
  name: 'TestReadinessLevel',
  props: {
    trl: {
      type: Number,
      default: 0, // Unevaluated is 0
    },
  },
  setup(props, context) {
    const resolveTestReadinessLevel = value => {
      if (value) {
        return store.state.constants.trls.find(e => e.id === value)
      }
      return store.state.constants.defaultTrl
    }
    const resolveClass = value => {
      if (value === 0) {
        return 'trl0'
      }
      if (value === 1) {
        return 'trl1'
      }
      if (value === 2) {
        return 'trl2'
      }
      if (value === 3) {
        return 'trl3'
      }
      if (value === 4) {
        return 'trl4'
      }
      if (value === 5) {
        return 'trl5'
      }
      if (value === 6) {
        return 'trl6'
      }
      if (value === 7) {
        return 'trl7'
      }
      if (value === 8) {
        return 'trl8'
      }
      if (value === 9) {
        return 'trl9'
      }
      return 'trl0'
    }

    return {
      resolveTestReadinessLevel,
      resolveClass,
    }
  },
}
</script>

<style scoped lang="scss">
.trl0 {
  background-color: #37474F;
}

.trl1 {
  background-color: #F3CBCB;
  color: #263238 !important;
}

.trl2 {
  background-color: #F3CBCB;
  color: #263238 !important;
}

.trl3 {
  background-color: #FFE497;
  color: #263238 !important;
}

.trl4 {
  background-color: #FFE497;
  color: #263238 !important;
}

.trl5 {
  background-color: #FFF2CB;
  color: #263238 !important;
}

.trl6 {
  background-color: #FFF2CB;
  color: #263238 !important;
}

.trl7 {
  background-color: #FFF2CB;
  color: #263238 !important;
}

.trl8 {
  background-color: #FFFFFF;
  color: #263238 !important;
}

.trl9 {
  background-color: #D8E9D2;
  color: #263238 !important;
}
</style>
