import store from '@/store'

const beforeEnter = (to, from, next) => {
  /**
   * This router-guard checks if a user has admin permissions, otherwise redirect them to the model browser page.
   * @type {string}
   * @private
   */
  const isAdmin = store.state.auth.roles?.includes('administrator') || false

  if (isAdmin) {
    next()
  } else {
    console.error('Unauthorised access attempt to an administrator page. This action has been logged.')
    console.error(`User: ${store.state.auth.id} (${store.state.auth.firstName} ${store.state.auth.lastName})`)
    console.error(to)
    next({ name: 'model_browse' })
  }
}

const routes = [
  {
    path: '/admin/session-management',
    name: 'admin_session_management',
    component: () => import('@/auth/views/admin/SessionManagement.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Active Sessions',
      auth: true,
      breadcrumb: [
        {
          text: 'Active Sessions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/user-management',
    name: 'admin_users',
    component: () => import('@/auth/views/admin/UserManagement/UserManagement.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'User Management',
      auth: true,
      breadcrumb: [
        {
          text: 'User Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/user-management/user/:userId',
    name: 'manage_user',
    component: () => import('@/auth/views/admin/UserManagement/UserProfile.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Manage User',
      auth: true,
      breadcrumb: [
        {
          text: 'User Management',
          to: { name: 'admin_users' },
        },
        {
          text: 'Manage User',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/banner',
    name: 'admin_banner',
    component: () => import('@/auth/views/admin/AdminBanner.vue'),
    beforeEnter,
    meta: {
      pageTitle: 'Announcement Banner',
      auth: true,
      breadcrumb: [
        {
          text: 'Announcement Banner',
          active: true,
        },
      ],
    },
  },
]

export default routes
