<template>
  <div class="canvas-container">
    <b-overlay
      :show="isGraphLoadingStatus"
      variant="transparent"
      no-fade
      blur="3px"
      :opacity="1"
      rounded="sm"
      class="canvas"
    >
      <behaviour-colour-key
        :refresh-colour-by="refreshColourBy"
        :graph="graph"
        :tree-data="selectedBt"
        :node-map="nodeMap"
        :focus-fn="focusNodeById"
        @onChangeColourBy="onChangeColourBy"
        @itemClicked="onClickColourKeyItem"
      />
      <div class="bn-toolbar">
        <b-dd variant="outline-secondary" no-caret>
          <template #button-content>
            <feather-icon icon="MenuIcon" />
          </template>
          <b-dropdown-item @click="showGenerateRequirements">
            Generate Requirements
          </b-dropdown-item>
          <b-dropdown-item @click="showGenerateTests">
            Generate Tests
          </b-dropdown-item>
          <b-dropdown-item @click="exportPdf">
            Export PDF
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item v-b-toggle:stencil-slide @click="isToolboxVisible = !isToolboxVisible">
            Toggle Toolbox
          </b-dropdown-item>
        </b-dd>

        <b-button-toolbar class="d-inline-flex" style="gap: 0.5rem;">
          <b-button
            v-b-tooltip.top.noninteractive="`${showTimings ? 'Hide' : 'Show'} Timings`"
            :pressed="showTimings"
            :variant="showTimings ? 'outline-primary' : 'outline-secondary'"
            @click="toggleTimings"
          >
            <feather-icon icon="ClockIcon" />
            <span class="toolbar-text">Timings</span>
          </b-button>
          <b-button
            v-b-tooltip.top.noninteractive="`${showXrefs ? 'Hide' : 'Show'} Cross References`"
            :pressed="showXrefs"
            :variant="showXrefs ? 'outline-primary' : 'outline-secondary'"
            @click="toggleXrefs"
          >
            <feather-icon icon="LinkIcon" />
            <span class="toolbar-text">X-Refs</span>
          </b-button>
          <b-button-group>
            <b-button v-b-tooltip.hover.top.noninteractive="'Collapse All (Ctrl + Shift + Minus)'" variant="outline-secondary" :disabled="isGraphLoadingStatus" @click="collapseAll">
              <feather-icon icon="ChevronsUpIcon" />
              <span class="toolbar-text">Collapse All</span>
            </b-button>
            <b-button v-b-tooltip.hover.top.noninteractive="'Expand All (Ctrl + Shift + Equals)'" variant="outline-secondary" :disabled="isGraphLoadingStatus" @click="expandAll">
              <feather-icon icon="ChevronsDownIcon" />
              <span class="toolbar-text">Expand All</span>
            </b-button>
          </b-button-group>
          <b-button variant="outline-secondary" title="Refresh" @click="refreshClicked">
            <feather-icon class="text-info" icon="RefreshCwIcon" />
            <span class="toolbar-text">Refresh</span>
          </b-button>
        </b-button-toolbar>
        <portal-target
          name="bt-colour-by-dropdown-items"
          slim
        />
        <fuse-search-box :search-list="selectedBtNodes" @resultClicked="focusSearchResult" />
      </div>
      <div id="mainCanvas" ref="canvas" :class="canvasClass" />
      <div class="stencil-container bn-node" :hidden="!isToolboxVisible">
        <b-collapse id="stencil-slide" visible>
          <div ref="stencilDiv" />
        </b-collapse>
      </div>
      <template #overlay>
        <div class="text-center">
          <atom-spinner
            style="width: 10rem; height: 10rem;"
          />
        </div>
      </template>
    </b-overlay>
    <b-sidebar v-if="isSidebarVisible"
               id="behaviourTreeSidebar"
               :class="miniModeClass"
               width="550px"
               bg-variant="white"
               right
               :visible="isSidebarVisible"
               @hidden="isSidebarVisible = false"
    >
      <resize-bar sidebar-id="behaviourTreeSidebar" />
      <BehaviourSidebar
        class="mx-2"
        :selected-behaviour-node="selectedBehaviourNode"
        :side-menu-data-loading="isSidebarLoading"
        :mini-mode="mini"
        @toggleMiniMode="onToggleMiniMode"
        @refocus="refocus"
        @updated="nodeUpdated"
        @updatedBT="btNodeUpdated"
        @update_focus="focusNodeById"
        @show_node="highlightNodeById"
      />
    </b-sidebar>
    <b-sidebar
      v-if="isBulkSidebarVisible"
      id="behaviourTreeSidebar"
      title="Bulk Association"
      bg-variant="white"
      shadow="true"
      width="550px"
      right
      :visible="isBulkSidebarVisible"
      @hidden="isBulkSidebarVisible = false"
    >
      <resize-bar sidebar-id="behaviourTreeSidebar" />
      <BulkOperationsSidebar
        class="mx-2"
        :side-menu-data-loading="isSidebarLoading"
        :nodes="bulkSelectedNodes"
      />
    </b-sidebar>
    <!-- Modals -->
    <div v-if="selectedBt">
      <delete-behaviour
        :show-boolean="deleteModalShow"
        @update-bool="deleteModalShow = $event"
        @deleted-node="postDeleteNode"
      />
      <copy-behaviour
        :show-boolean="copyModalShow"
        @update-bool="copyModalShow = $event"
        @duplicated="duplicateAfterFunction"
      />
      <AddBehaviourModal
        id="add-behaviour-modal"
        title="Add Behaviour"
        :p_type="addNodeType"
        :show-boolean="addModalShow"
        @added-node="postAdd"
        @update-bool="addModalShow = $event"
      />
      <AddBehaviourModal
        id="add-precond-modal"
        title="Add Precondition"
        p_type="Selection"
        p_direction="before"
        :show-boolean="precondModalShow"
        @added-node="postAddPrecond"
        @update-bool="precondModalShow = $event"
      />
      <edit-timing-constraint
        :selected-b-ns="timingNodeIds"
        :show-boolean="performanceModalShow"
        updated-timing="postTiming"
        @update-bool="performanceModalShow = $event"
      />
      <FunctionSelectModal
        :show-boolean="functionModalShow"
        sel-fn="instantiateFn1"
        @update-bool="functionModalShow = $event"
      />
      <InterfaceSelect
        :show-boolean="interfaceModalShow"
        input="instantiateInterface"
        @update-bool="interfaceModalShow = $event"
      />
      <RefineBehaviourModal ref="refineBehaviourModal" />
      <GenerateRequirementsBT />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import axiosIns from '@/libs/axios'
import {
  ref, watch, computed, onMounted, onUnmounted, nextTick, reactive, getCurrentInstance,
} from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import {
  g, dia, ui, shapes, layout, linkTools,
} from '@clientio/rappid'
import { useMediaQuery } from '@vueuse/core'
// Components
import BehaviourSidebar from '@/views/Behaviour/BehaviourSidebar.vue'
import BulkOperationsSidebar from '@/views/Behaviour/BulkOperationsSidebar.vue'
import ResizeBar from '@core/components/resize-bar.vue'
import ShowTestsModal from '@/components/Behaviours/Modals/showTests.vue'
import BehaviourColourKey from '@/views/Behaviour/JointJSGraph/components/ColourBy/BehaviourColourKey.vue'
import SpecificationPicker from '@/components/Specifications/SpecificationPicker.vue'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
// Modals
import DeleteBehaviour from '@/components/Behaviours/Modals/DeleteBehaviour.vue'
import CopyBehaviour from '@/components/Behaviours/Modals/CopyBehaviour.vue'
import AddBehaviourModal from '@/components/Behaviours/Modals/AddBehaviour.vue'
import EditTimingConstraint from '@/components/Behaviours/Modals/EditTimingConstraint.vue'
import FunctionSelectModal from '@/components/Domain/Modals/FunctionSelectModal.vue'
import InterfaceSelect from '@/components/Domain/Modals/Interfaces/InterfaceSelect.vue'
import RefineBehaviourModal from '@/components/Behaviours/Modals/RefineBehaviourModal.vue'
import ShowEnablementModal from '@/components/Behaviours/Modals/ShowEnablement.vue'
import GenerateRequirementsBT from '@/components/Behaviours/Modals/GenerateRequirementsBT.vue'
// eslint-disable-next-line import/named
import { MoveMenuService } from '@/views/Behaviour/JointJSGraph/services/moveMenuService'
import { XrefMenuService } from '@/views/Behaviour/JointJSGraph/services/xrefMenuService'
import { StencilService } from '@/views/Behaviour/JointJSGraph/services/stencilService'
import { HaloService } from '@/views/Behaviour/JointJSGraph/services/haloService'
import { HaloExpandService } from '@/views/Behaviour/JointJSGraph/services/haloExpandService'
import { ContextToolbarService } from '@/views/Behaviour/JointJSGraph/services/contextToolbarService'
import { useJointJs } from '@/components/Generic/Graph/useJointJs'
import { useJointJsTree } from '@/components/Generic/Graph/useJointJsTree'

import FuseSearchBox from '@core/components/fuse-search-box/FuseSearchBox.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GenerateRequirementsFn from '@/components/Domain/Modals/GenerateRequirementsFn.vue'
import coreService from '@/libs/api-services/core-service'
import * as jointBTFunctions from './behaviourJointJSFunctions'
import {
  addXrefLink,
  copyNodes,
  moveNodes,
  reorderSiblings,
  integrateNodes,
} from './behaviourJointJSFunctions'
import { createCustomShape } from './CustomShapes/customShapeFunctions'

export default {
  name: 'BehaviourTreeJoint',
  components: {
    AtomSpinner,
    FuseSearchBox,
    RefineBehaviourModal,
    BehaviourSidebar,
    BulkOperationsSidebar,
    BehaviourColourKey,
    // Modals
    DeleteBehaviour,
    CopyBehaviour,
    EditTimingConstraint,
    AddBehaviourModal,
    FunctionSelectModal,
    InterfaceSelect,
    GenerateRequirementsBT,
    ResizeBar,
  },
  setup(props, context) {
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)
    const routeQuery = computed(() => route.value.query)

    const isLargeScreen = useMediaQuery('(min-height: 2000px)')
    const isSmallScreen = useMediaQuery('(max-height: 1350px)')

    const mini = ref(localStorage.getItem('behaviourTreeMiniMode') === 'true')
    const miniModeClass = computed(() => (mini.value ? 'mini' : ''))
    const canvas = ref(null)
    const canvasClass = ref('canvas bn-node')
    const refreshColourBy = ref(true)
    const stencilDiv = ref(null)

    const showTimings = ref(false)
    const showXrefs = ref(false)
    const addNodeType = ref('Event')
    const isGraphLoadingStatus = ref(false)
    const searchText = ref('')
    const searchResults = ref([])
    const hiddenNodeIds = ref([])
    const isKeyVisible = ref(true)
    const isToolboxVisible = ref(true)

    const {
      graph, paper, nav, scroller, selection, keyboard, bulkSelectedNodes, graphLayout, exporters,
    } = useJointJs(canvas)
    const treeOptions = {
      nodeTransformFn: n => n,
      nodeFilterFn: n => true,
      // on initial load, expand from root abstract node to the first of each descendant abstract nodes
      nodeHideFn: n => {
        if (nodeMap[nodeMap[n.id].pid]) {
          return nodeMap[nodeMap[n.id].pid].type === 'FunctionNode' && nodeMap[nodeMap[n.id].pid].pid
        }
        console.error(`Potentially orphaned behaviour node not displayed in graph: '${n.name} (${n.id})'`)
        console.error(n)
        return false
      },
      createShape: n => createCustomShape(n, null, store.state),
      addLink,
      pageSize: 100,
    }
    const {
      nodeMap, treeData, showAllAncestors, afterLoadTree, createTree, refreshTree, redrawNodes,
    } = useJointJsTree(treeOptions)
    const stencilService = new StencilService()
    const contextToolbarService = new ContextToolbarService()
    const haloService = new HaloService()
    const haloExpandService = new HaloExpandService()

    function onChangeColourBy(className) {
      canvasClass.value = `canvas ${className}`
    }

    paper.on('blank:pointerup', evt => {
      // If any nodes were selected using the drag
      if (selection.collection.models.length > 0 && evt.originalEvent.ctrlKey) {
        if (!evt.shiftKey) {
          bulkSelectedNodes.value = []
        }
        selection.collection.models.forEach(cell => {
          bulkSelectedNodes.value.push(cell.id)
        })
        bulkSelectedNodes.value = [...new Set(bulkSelectedNodes.value)]
        if (evt.shiftKey) {
          // UNION mode (add bulk to existing bulk selection)
          selection.collection.reset(graph.getElements().filter(e => bulkSelectedNodes.value.includes(e.id)))
        }
        if (selection.collection.models.length > 1) {
          // If more than 1 node was selected
          jointBTFunctions.openSidebar({ single: isSidebarVisible, bulk: isBulkSidebarVisible })
        } else {
          // Only 1 node was selected
          jointBTFunctions.openSelectedBNSidebar(
            { single: isSidebarVisible, bulk: isBulkSidebarVisible, loading: isSidebarLoading },
            store,
            selection.collection.models[0],
          )
        }
      }
    })

    // Keyboard events
    // -- Multiple select
    const isCtrlPressed = ref(false)
    keyboard.on('keydown:ctrl', evt => {
      isCtrlPressed.value = true
    })
    keyboard.on('keyup:ctrl', evt => {
      isCtrlPressed.value = false
    })
    keyboard.on('ctrl+/', evt => {
      evt.preventDefault()
      isCtrlPressed.value = false
      nextTick(() => {
        try {
          const ele = document.querySelector('#bn-search-dropdown .vs__search')
          ele.select()
          ele.focus()
          // throws exceptions even though it works
          // eslint-disable-next-line no-empty
        } catch (e) {
          console.error(e)
        }
      })
    })
    keyboard.on('ctrl+=', evt => {
      evt.preventDefault()
      isCtrlPressed.value = false
      if (bulkSelectedNodes.value.length > 0) {
        expandBranch(bulkSelectedNodes.value[0])
      }
    })
    keyboard.on('ctrl+shift+=', evt => {
      evt.preventDefault()
      isCtrlPressed.value = false
      expandAll()
    })
    keyboard.on('ctrl+shift+-', evt => {
      evt.preventDefault()
      isCtrlPressed.value = false
      collapseAll()
    })
    keyboard.on('ctrl+d', async evt => {
      evt.preventDefault()
      isCtrlPressed.value = false
      if (bulkSelectedNodes.value.length > 0) {
        await store.dispatch('behaviours/selectBehaviourNode', bulkSelectedNodes.value[0])
        copyModalShow.value = true
      }
    })
    keyboard.on('ctrl+a', async evt => {
      evt.preventDefault()
      isCtrlPressed.value = false
      if (bulkSelectedNodes.value.length > 0) {
        await store.dispatch('behaviours/selectBehaviourNode', bulkSelectedNodes.value[0])
        addModalShow.value = true
      }
    })
    keyboard.on('keyup:space', evt => {
      evt.preventDefault()
      if (bulkSelectedNodes.value.length > 0) {
        mini.value = true
        isSidebarVisible.value = true
        isBulkSidebarVisible.value = false
        focusNode(graph.getCell(bulkSelectedNodes.value[0]), scroller, bulkSelectedNodes, selection)
      }
    })
    keyboard.on('keyup:enter', evt => {
      evt.preventDefault()
      if (bulkSelectedNodes.value.length > 0) {
        mini.value = false
        isSidebarVisible.value = true
        isBulkSidebarVisible.value = false
        focusNode(graph.getCell(bulkSelectedNodes.value[0]), scroller, bulkSelectedNodes, selection)
      }
    })
    keyboard.on('keyup:up', evt => {
      if (evt.type !== 'keyup') return
      if (evt.key !== 'ArrowUp') return
      evt.preventDefault()
      // go to the parent of the selected node
      if (bulkSelectedNodes.value.length > 0) {
        const currentNodeId = bulkSelectedNodes.value[0]
        if (nodeMap[currentNodeId].pid) {
          bulkSelectedNodes.value = []
          bulkSelectedNodes.value.push(nodeMap[currentNodeId].pid)
          focusNode(graph.getCell(bulkSelectedNodes.value[0]), scroller, bulkSelectedNodes, selection)
        }
      }
    })
    // Selecting behaviour node
    const isSidebarVisible = ref(false)
    const reqPickSidebarVisible = ref(false)
    const testPickSidebarVisible = ref(false)
    const isBulkSidebarVisible = ref(false)
    const isSidebarLoading = ref(false)
    const cellHighlighter = {
      highlighter: {
        name: 'stroke',
        options: {
          padding: 10,
          rx: 10,
          ry: 10,
          attrs: {
            stroke: 'cornflowerblue',
            'stroke-width': 3,
          },
        },
      },
    }
    const onLinkAdd = link => {
      const src = link.get('source')
      const tgt = link.get('target')
      if (!src.id || !tgt.id || src.id === tgt.id) {
        link.remove()
        return
      }
      const xrefMenuService = new XrefMenuService()
      const tgtCell = graph.getCell(tgt.id)
      const { x, y } = paper.localToClientPoint(tgtCell.position())
      const menu = xrefMenuService.create(x, y)
      menu.on('action:timing', async () => {
        menu.remove()
        timingNodeIds.value = [src.id, tgt.id]
        performanceModalShow.value = true
      })
      XrefMenuService.actions
        .filter(a => a.action !== 'timing')
        .forEach(a => {
          menu.on(`action:${a.action}`, async () => {
            menu.remove()
            createBnRelationship(src.id, tgt.id, a.action)
            await updateGraph()
          })
        })
      link.remove()
    }

    function focusNode(element, scroller, bulkSelectedNodes, selection) {
      jointBTFunctions.focusNode(element, scroller, bulkSelectedNodes, selection)
      selectNode(element)
    }

    async function focusNodeById(id) {
      let element = graph.getCell(id)
      if (!element) {
        const [ancestors, isUnhidden] = await showAllAncestors(id)
        if (isUnhidden) {
          await redrawGraph()
          element = graph.getCell(id)
        }
      }
      jointBTFunctions.focusNode(element, scroller, bulkSelectedNodes, selection)
      selectNode(element)
    }

    function highlightNodeById(id) {
      const element = graph.getCell(id)
      jointBTFunctions.focusNode(element, scroller, bulkSelectedNodes, selection)
    }

    function onShowChildren(element) {
      const children = nodeMap[element.id]._children
      const firstChild = children[0]
      if (firstChild) {
        // show this node's children
        hiddenNodeIds.value = hiddenNodeIds.value.filter(id => !children.includes(id))
        children.forEach(cid => { nodeMap[cid]._hidden = false })
        redrawGraph()
        focusNode(element, scroller, bulkSelectedNodes, selection)
      }
    }

    function onHideChildren(element) {
      const children = nodeMap[element.id]._children
      const firstChild = children[0]
      if (firstChild) {
        // hide this node's children
        const combined = [...hiddenNodeIds.value, ...children]
        hiddenNodeIds.value = [...new Set(combined)]
        children.forEach(cid => { nodeMap[cid]._hidden = true })
        redrawGraph()
        // focusNode(element, scroller, bulkSelectedNodes, selection)
      }
    }

    async function selectNode(element, shouldLoad = true) {
      if (shouldLoad) await store.dispatch('behaviours/selectBehaviourNode', element.id)
      haloService.createHalo(element, paper, graph, onLinkAdd, onHideChildren, nodeMap[element.id]._descendantCount)
      haloExpandService.createHalo(element, paper, graph, onShowChildren, nodeMap[element.id]._descendantCount)
      const { href } = router.resolve({
        name: 'joint_mbse_tree_focus',
        params: { ...routeParams.value },
        query: { focus: element.id },
      })
      window.history.pushState({}, null, href)
    }

    paper.on('cell:pointerclick', (cellView, evt, x, y) => {
      if (cellView.model.isLink()) {
        const mouse = paper.clientToLocalPoint(evt.clientX, evt.clientY)
        const src = graph.getCell(cellView.model.source().id)
        const tgt = graph.getCell(cellView.model.target().id)
        const srcDist = cellView.sourcePoint.distance(mouse)
        const tgtDist = cellView.targetPoint.distance(mouse)
        if (bulkSelectedNodes.value[0] && bulkSelectedNodes.value[0] === src.id) {
          focusNode(tgt, scroller, bulkSelectedNodes, selection)
          return
        }
        if (bulkSelectedNodes.value[0] && bulkSelectedNodes.value[0] === tgt.id) {
          focusNode(src, scroller, bulkSelectedNodes, selection)
          return
        }
        if (srcDist > tgtDist) {
          focusNode(src, scroller, bulkSelectedNodes, selection)
        } else {
          focusNode(tgt, scroller, bulkSelectedNodes, selection)
        }
        return
      }
      jointBTFunctions.nodeClickEvent({
        clickedNode: cellView,
        clickEvent: evt,
        isCtrlKeyPressed: isCtrlPressed.value,
        jointSelection: selection,
        sidebarConditionals: {
          single: isSidebarVisible,
          bulk: isBulkSidebarVisible,
          loading: isSidebarLoading,
        },
        vuexStore: store,
        selectedNodesArray: bulkSelectedNodes,
      })
      selectNode(cellView.model, false)
    })

    paper.on('cell:pointerdown', (elementView, evt) => {
      if (elementView.model.isLink()) return
      treeLayoutView.startDragging([elementView.model])
    })

    async function focusOnRouteNode() {
      if (routeQuery.value.focus) {
        console.log('routeQuery.value.focus', routeQuery.value.focus)
        const [_, unhidden] = await showAllAncestors(routeQuery.value.focus)
        if (unhidden) {
          await redrawGraph()
        }
        const focusNode = graph.getCell(routeQuery.value.focus)
        if (!focusNode) {
          console.warn('Node not drawn in graph, unable to focus on it', routeQuery.value.focus)
          return
        }
        jointBTFunctions.focusNode(focusNode, scroller, bulkSelectedNodes, selection)
      }
    }

    watch(
      () => routeQuery.value,
      (newParams, oldParams) => {
        console.log('routequery watcher focusOnRouteNode', newParams, oldParams)
        focusOnRouteNode()
      },
    )

    watch(
      () => routeParams.value,
      newParams => {
        // handle changing BTs from with a BT (e.g. clicking refinement links)
        if (newParams.behaviourTreeId !== selectedBt.value.id) {
          initialLoad = true
          // this will trigger the selectedBt watcher
          store.dispatch('behaviours/selectBehaviourTree', route.value.params.behaviourTreeId)
        }
      },
    )

    watch(
      () => store.state.issues.update_graph,
      () => {
        // Call updateGraph() whenever issues change
        updateGraph()
        store.commit('issues/SET_UPDATE_GRAPH', null)
      },
    )

    // Context Menu
    // Modal show booleans
    const addModalShow = ref(false)
    const deleteModalShow = ref(false)
    const copyModalShow = ref(false)
    const precondModalShow = ref(false)
    const performanceModalShow = ref(false)
    const functionModalShow = ref(false)
    const interfaceModalShow = ref(false)
    const refineBehaviourModal = ref(null)
    const timingNodeIds = ref([])

    // Find parent ID of the cell if it exists
    let parentElement
    // Context menu event
    paper.on('cell:contextmenu', triggerContextMenu)

    function triggerContextMenu(cellView, evt) {
      if (cellView.model.isLink()) return
      // eslint-disable-next-line prefer-destructuring
      if (!graph.isSource(cellView.model)) parentElement = graph.getPredecessors(cellView.model)[0]
      else parentElement = null
      // Render the menu
      const contextToolbar = contextToolbarService.createContextMenu(cellView, parentElement, evt.clientX, evt.clientY)
      handleContextActions(contextToolbar)
      document.querySelector('.joint-context-toolbar').addEventListener('contextmenu', evt => {
        // Because JointJS only prevents default on paper elements, it doesn't apply to the context menu that happens
        // to be right under your mouse when you right-click, which just sets off the default event :)
        evt.preventDefault()
      })
    }

    function selectChildrenNodes(node, isSelectionWithSelf, jointSelection, selectedNodesArray, graph) {
      // Reset the arrays
      jointSelection.collection.reset([])
      selectedNodesArray.value = []
      // If select `WITH` Children
      const cells = []
      if (isSelectionWithSelf) {
        cells.push(node.model)
      }
      // Add children to selection
      cells.push(...graph.getSuccessors(node.model))
      jointSelection.collection.reset(cells)
      selectedNodesArray.value.push(...cells.map(c => c.id))
      // Open Bulk sidebar
      openSidebar(selectedNodesArray.value.length > 1)
    }

    function openSidebar(bulk = false) {
      isSidebarVisible.value = false
      isSidebarVisible.value = !bulk
      isBulkSidebarVisible.value = bulk
    }

    const instance = getCurrentInstance()

    function handleContextActions(contextToolbar) {
      const node = contextToolbarService.contextElement
      contextToolbar.on('action:addChild', async evt => {
        contextToolbar.remove()
        await store.dispatch('behaviours/selectBehaviourNode', node.model.id)
        addModalShow.value = true
      })
      contextToolbar.on('action:deleteNode', async evt => {
        contextToolbar.remove()
        await store.dispatch('behaviours/selectBehaviourNode', node.model.id)
        const hasParent = selectedBt.value.edges.find(e => e.target === node.model.id)
        if (!hasParent) {
          await instance.proxy._bv__modal.msgBoxOk("You can't delete the root node", { centered: true })
        } else {
          deleteModalShow.value = true
        }
      })
      contextToolbar.on('action:copyNode', async evt => {
        contextToolbar.remove()
        await store.dispatch('behaviours/selectBehaviourNode', node.model.id)
        copyModalShow.value = true
      })
      contextToolbar.on('action:duplicateNode', async evt => {
        contextToolbar.remove()
        const fields = {
          source: node.model.id,
          target: selectedBt.value.edges.find(e => e.target === node.model.id).source,
          bt: selectedBt.value.id,
          rel_type: 'sequence',
          with_children: false,
          with_requirements: false,
          with_issues: false,
        }
        isGraphLoadingStatus.value = true
        const result = await axiosIns.post('/api/v2/behaviour/copy_node', fields)
        await store.dispatch('behaviours/selectBehaviourNode', result.data.copy)
        await updateGraph()
        focusNodeById(result.data.copy)
      })
      contextToolbar.on('action:addPrecondition', evt => {
        // TODO: Select parent
        store.dispatch('behaviours/selectBehaviourNode', node.model.id)
          .then(() => {
            precondModalShow.value = true
          })
        contextToolbar.remove()
      })
      contextToolbar.on('action:refineBehaviour', async evt => {
        await store.dispatch('behaviours/selectBehaviourNode', node.model.id)
        try {
          const result = await refineBehaviourModal.value.showAsync()
        } catch (e) {
          console.error('Refine failed:', e)
        }
        contextToolbar.remove()
      })
      contextToolbar.on('action:selectWithChildren', evt => {
        const isSelectionWithSelf = true
        selectChildrenNodes(node, true, selection, bulkSelectedNodes, graph)
        contextToolbar.remove()
      })
      contextToolbar.on('action:selectChildren', evt => {
        selectChildrenNodes(node, false, selection, bulkSelectedNodes, graph)
        contextToolbar.remove()
      })
      contextToolbar.on('action:expandBranch', evt => {
        expandBranch(node.model.id)
        contextToolbar.remove()
      })
      contextToolbar.on('action:instantiateFunction', evt => {
        store.dispatch('behaviours/selectBehaviourNode', node.model.id)
          .then(() => {
            functionModalShow.value = true
          })
        contextToolbar.remove()
      })
      contextToolbar.on('action:instantiateInterface', evt => {
        store.dispatch('behaviours/selectBehaviourNode', node.model.id)
          .then(() => {
            interfaceModalShow.value = true
          })
        contextToolbar.remove()
      })
    }

    function postDeleteNode() {
      // Update graph
      updateGraph()
      // Focus on node
      jointBTFunctions.focusNode(parentElement, scroller, bulkSelectedNodes, selection)
    }

    async function postAdd(data) {
      addModalShow.value = false
      await updateGraph()
      // Select the new created node by its graph element
      const newNode = graph.getCell(data.nodes[0].id)
      jointBTFunctions.focusNode(newNode, scroller, bulkSelectedNodes, selection)
    }

    async function postAddPrecond(data) {
      precondModalShow.value = false
      await updateGraph()
      // Select the new created node by its graph element
      const newNode = graph.getCell(data.nodes[0].id)
      jointBTFunctions.focusNode(newNode, scroller, bulkSelectedNodes, selection)
    }

    function renameAfterFunction() {
      updateGraph()
    }

    function duplicateAfterFunction() {
      updateGraph()
      // Focusing is done on watch for route
    }

    // handleDragDropNodes: treeLayoutView reconnectElements triggers a menu
    // to replicate functionality for dragging nodes onto other nodes
    // to move/copy them
    function handleDragDropNodes(elements, parent, siblingRank) {
      // elements is an array because of multiselect
      const dragElement = elements[0]
      if (!dragElement) return
      const moveMenuService = new MoveMenuService()
      const { x, y } = paper.localToClientPoint(parent.position())
      const menu = moveMenuService.createMoveMenu(dragElement, x, y)
      menu.on('action:integrate', async () => {
        isGraphLoadingStatus.value = true
        menu.remove()
        await integrateNodes(store, dragElement.id, parent.id, selectedBt.value.id)
        await updateGraph()
      })
      menu.on('action:move', async () => {
        isGraphLoadingStatus.value = true
        menu.remove()
        if (parent.id === selectedBt.value.edges.find(e => e.target === dragElement.id).source) {
          // parent has not changed, move is a sibling reorder
          await reorderSiblings(store, dragElement.id, parent.id, siblingRank)
        } else {
          // changing parent
          await moveNodes(dragElement.id, parent.id, selectedBt.value.id)
        }
        await updateGraph()
      })
      menu.on('action:copyOne', async () => {
        isGraphLoadingStatus.value = true
        menu.remove()
        await copyNodes(dragElement.id, parent.id, selectedBt.value.id, false)
        await updateGraph()
      })
      menu.on('action:copyWithChildren', async () => {
        isGraphLoadingStatus.value = true
        menu.remove()
        await copyNodes(dragElement.id, parent.id, selectedBt.value.id, true)
        await updateGraph()
      })
    }

    const treeLayoutView = new ui.TreeLayoutView({
      paper,
      model: graphLayout,
      validateConnection: (element, candidateParent) => !!candidateParent,
      validatePosition: () => false,
      reconnectElements: (elements, parent, siblingRank) => {
        handleDragDropNodes(elements, parent, siblingRank)
      },
    })
    let stencil = null

    scroller.zoomToFit()

    const selectedBehaviourNode = computed(() => store.state.behaviours.selectedBehaviourNode)
    const selectedBt = computed(() => store.state.behaviours.selectedBehaviourTree)
    watch(
      () => selectedBt.value,
      async newBt => {
        await redrawGraph()
        focusOnRouteNode()
      },
    )
    const selectedBtNodes = computed(() => selectedBt.value?.nodes || [])

    async function focusSearchResult(result) {
      if (result) {
        if (!graph.getCell(result.id)) {
          showAllAncestors(result.id)
          await redrawGraph()
        }
        focusNode(paper.findViewByModel(result.id).model, scroller, bulkSelectedNodes, selection)
        searchResults.value = []
        searchText.value = ''
      }
    }

    function redrawExpandHalos() {
      console.time('btredrawExpandHalos')
      const eles = graph.getElements()
      eles.forEach(ele => {
        if (nodeMap[ele.id]._children.filter(cId => nodeMap[cId]._hidden).length > 0) {
          haloExpandService.createHalo(ele, paper, graph, onShowChildren, nodeMap[ele.id]._descendantCount)
        }
      })
      console.timeEnd('btredrawExpandHalos')
    }

    function addLink(source, target) {
      return new shapes.standard.Link({ source, target })
    }

    function calculateSafeRoot() {
      const allRoots = selectedBt.value.roots.map(r => r.root)
      let root = allRoots[0]
      if (allRoots.length > 1) {
        // prefer an abstract function node as root
        root = allRoots.find(r => selectedBt.value.nodes.find(n => n.id === r && n.type === 'FunctionNode')) || root
        // add edges for all the other roots to make them non-roots
        allRoots.filter(r => r !== root).forEach(r => {
          if (!selectedBt.value.edges.find(edge => edge.target === r)) {
            selectedBt.value.edges.push({
              rel_props: {
                in_bt: selectedBt.value.id,
                probability: 1,
                rel_type: 'sequence',
                usage: 'Nominal',
                usage_value: 1.0,
              },
              source: root,
              target: r,
            })
          }
        })
      }
      return root
    }

    let initialLoad = true
    async function redrawGraph() {
      isGraphLoadingStatus.value = true
      console.time('btRedraw')
      if (!selectedBt.value?.nodes) {
        console.warn('Had to reload the BT', selectedBt.value, route.value.params)
        await store.dispatch('behaviours/selectBehaviourTree', route.value.params.behaviourTreeId)
      }
      selectedBt.value.root = calculateSafeRoot()
      afterLoadTree(selectedBt.value)
      await createTree(initialLoad)
      initialLoad = false
      paper.freeze()
      const allCells = await redrawNodes()
      graph.resetCells(allCells)
      graphLayout.layout()
      const links = addXrefs()
      isGraphLoadingStatus.value = false
      links.forEach(l => {
        requestAnimationFrame(() => {
          graph.addCell(l, { sort: false })
        })
      })
      nav()
      refreshColourBy.value = !refreshColourBy.value
      paper.unfreeze()
      redrawExpandHalos()
      console.timeEnd('btRedraw')
    }

    function nodeUpdated(data) {
      if (data) {
        const node = selectedBt.value.nodes.find(n => n.id === data.id)
        Object.assign(node, data)
        // Propagate changes through the graph if other nodes were affected by the update in the backend
        if (data.other_nodes) {
          data.other_nodes.forEach(otherNode => {
            const graphNode = selectedBt.value.nodes.find(n => n.id === otherNode.id)
            Object.assign(graphNode, otherNode)
          })
        }
      }
      if (showXrefs.value) {
        updateGraph()
      } else {
        redrawGraph()
      }
    }

    function btNodeUpdated() {
      updateGraph()
    }

    async function updateGraph() {
      isGraphLoadingStatus.value = true
      let btId = selectedBt.value?.id
      if (!selectedBt.value || routeParams.value?.behaviourTreeId !== selectedBt.value.id) {
        btId = routeParams.value.behaviourTreeId
      }
      const result = await store.dispatch('behaviours/selectBehaviourTree', btId)
      if (result) {
        await store.commit('app/SET_DYNAMIC_PAGE_TITLE', result.properties.name)
        // Wait for nextTick here because callers rely on updated data from computed watcher
        await nextTick(() => {
        })
      }
    }

    function addXrefs() {
      const extraLinks = []
      if (showXrefs.value) {
        if (selectedBt.value.references) {
          selectedBt.value.references.forEach(ref => {
            const l = addXrefLink(graph, ref.source, ref.target, 'blue', ref.rel_type)
            extraLinks.push(l)
          })
        }
      }
      if (showTimings.value) {
        if (selectedBt.value.timing) {
          selectedBt.value.timing.forEach(xref => {
            const label = xref.rel_props?.timing_string
            const l = addXrefLink(graph, xref.source, xref.target, 'orange', label)
            extraLinks.push(l)
          })
        }
      }
      return extraLinks
    }

    async function toggleXrefs() {
      showXrefs.value = !showXrefs.value
      await updateGraph()
    }

    async function toggleTimings() {
      showTimings.value = !showTimings.value
      await updateGraph()
    }

    async function postTiming() {
      await updateGraph()
    }

    async function createBnRelationship(fromId, toId, relationship) {
      const params = {
        source: fromId,
        target: toId,
        bt: selectedBt.value.id,
        relationship,
        model: routeParams.value.modelId,
      }
      const result = await axiosIns.post('/api/v2/behaviour/create_bn_relationship', params)
      if (result.status !== 200) {
        context.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error creating link',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        return
      }
      showXrefs.value = true
      if (selectedBehaviourNode.value?.details?.id === fromId || selectedBehaviourNode.value?.details?.id === toId) {
        // reload the BN context for the sidebar if it is involved in the newly created link
        await store.dispatch('behaviours/selectBehaviourNode', selectedBehaviourNode.value.details?.id)
      }
      nodeUpdated({id: fromId, operator: relationship})
    }

    function onToggleMiniMode() {
      mini.value = !mini.value
    }

    function renderStencil() {
      const onDragEnd = async (cellView, targetElement) => {
        const { tag } = cellView.model.attributes.attrs
        const toId = targetElement.model.id
        if (tag.type === 'xref') {
          if (bulkSelectedNodes.value.length !== 1) return
          const fromId = bulkSelectedNodes.value[0]
          await createBnRelationship(fromId, toId, 'xref')
          await updateGraph()
        } else if (tag.type === 'timing') {
          if (bulkSelectedNodes.value.length !== 1) return
          const fromId = bulkSelectedNodes.value[0]
          timingNodeIds.value = [fromId, toId]
          performanceModalShow.value = true
          showTimings.value = true
        } else {
          await store.dispatch('behaviours/selectBehaviourNode', toId)
          addNodeType.value = tag.title
          addModalShow.value = true
        }
      }

      function getLinkStart() {
        return graph.getCell(bulkSelectedNodes.value[0])
      }

      stencil = stencilService.createStencil(paper, onDragEnd, getLinkStart)
      stencilDiv.value.appendChild(stencil.el)
      stencilService.loadStencil()
    }

    function autoToggleStencilGroups() {
      if (isSmallScreen.value) {
        stencil.closeGroups()
      }
      if (isLargeScreen.value) {
        stencil.openGroups()
      }
    }

    async function operatorLinkHandler(event) {
      event.preventDefault()
      const nodeId = event.target.parentNode.getAttribute('model-id')
      const allRefs = [...selectedBt.value.references, ...selectedBt.value.timing]
      const foundRef = allRefs.find(r => r.source === nodeId || r.target === nodeId)
      if (foundRef) {
        focusNodeById(foundRef.source === nodeId ? foundRef.target : foundRef.source)
      } else {
        if (nodeMap[nodeId].operator !== 'refined') return
        if (selectedBehaviourNode.value.details.id === nodeId && selectedBehaviourNode.value.refinements?.length > 0) {
          const refinement = selectedBehaviourNode.value.refinements[0]
          if (await context.root.$bvModal
            .msgBoxConfirm(`Are you sure you wish to open ${refinement.bt.name}?`, {
              title: 'Open Refinement Behaviour Tree',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Open',
              cancelTitle: 'Cancel',
              hideHeaderClose: false,
              centered: true,
            })) {
            router.push({
              name: 'joint_mbse_tree_focus',
              params: { behaviourTreeId: refinement.bt.id },
              query: { focus: refinement.id },
            })
          }
        }
      }
    }

    onMounted(async () => {
      canvas.value.appendChild(scroller.el)
      renderStencil()
      await updateGraph()
      scroller.center()
      paper.unfreeze()
      paper.scale(0.85)
      watch(isLargeScreen, () => autoToggleStencilGroups())
      watch(isSmallScreen, () => autoToggleStencilGroups())
      autoToggleStencilGroups()
      // load components so that modals have autocomplete
      store.dispatch('domainModel/getComponents')
      await focusOnRouteNode()
      window.olh = operatorLinkHandler
    })

    onUnmounted(() => {
      keyboard.off('ctrl+/')
      keyboard.off('ctrl+d')
      keyboard.off('ctrl+a')
      keyboard.off('ctrl+=')
      keyboard.off('ctrl+shift+=')
      keyboard.off('ctrl+shift+-')
      delete window.operatorLinkHandler
    })

    function onClickColourKeyItem(keyName) {
      const nodes = graph.getElements().filter(c => c.attr('body/class').includes(keyName.replaceAll(' ', '').toLowerCase()))
      if (nodes.length > 0) {
        bulkSelectedNodes.value = nodes.map(n => n.id)
        selection.collection.reset(nodes)
        focusNode(nodes[0], scroller, bulkSelectedNodes, selection)
      }
    }

    function showGenerateRequirements() {
      context.root.$bvModal.show('generate-bt-requirements-modal')
    }

    async function showGenerateTests() {
      try {
        const { data } = await axiosIns.get(`/api/v2/behaviour/generate_tests/${selectedBt.value.id}`, { params: { model: routeParams.value.modelId } })
        context.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Test Generation Complete',
            icon: 'CheckIcon',
            text: `${data.tests_generated} generated in ${data.time.toFixed(2)} seconds`,
            variant: 'success',
          },
        })
      } catch (e) {
        console.error(e)
        context.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error generating tests',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    }

    function refocus() {
      const selectedNode = bulkSelectedNodes?.value[0]
      if (selectedNode) {
        focusNode(graph.getCell(selectedNode), scroller, bulkSelectedNodes, selection)
      }
    }

    async function refreshClicked() {
      await updateGraph()
    }

    function hideSidebars() {
      reqPickSidebarVisible.value = false
      testPickSidebarVisible.value = false
      isBulkSidebarVisible.value = false
      isSidebarVisible.value = false
    }

    function expandBranch(nodeId) {
      isGraphLoadingStatus.value = true
      function _expandBranch(nodeId) {
        nodeMap[nodeId]._children.forEach(i => { _expandBranch(i); nodeMap[i]._hidden = false })
      }
      _expandBranch(nodeId)
      nodeMap[nodeId]._hidden = false
      redrawGraph()
    }

    async function onRequirementUnlinked() {
      hideSidebars()
      await updateGraph()
      reqPickSidebarVisible.value = true
    }

    async function expandAll() {
      isGraphLoadingStatus.value = true
      Object.values(nodeMap).forEach(i => { i._hidden = false })
      await redrawGraph()
    }

    async function collapseAll() {
      isGraphLoadingStatus.value = false
      Object.values(nodeMap).filter(i => i.type === 'FunctionNode' && i.id !== treeData.rootId)
        .forEach(i => { if (i._children.length > 0 && i.pid) { i._hidden = true } })
      await redrawGraph()
    }

    // END SETUP
    return {
      // Graph stuff
      graph,
      dia,
      paper,
      canvas,
      scroller,
      stencil,
      stencilDiv,
      nav,
      selection,
      updateGraph,
      addNodeType,
      isGraphLoadingStatus,
      selectedBt,
      selectedBtNodes,

      // Behaviour variables
      isSidebarVisible,
      isBulkSidebarVisible,
      isSidebarLoading,
      bulkSelectedNodes,
      reqPickSidebarVisible,
      testPickSidebarVisible,

      // Modal booleans
      copyModalShow,
      deleteModalShow,
      addModalShow,
      precondModalShow,
      performanceModalShow,
      functionModalShow,
      interfaceModalShow,
      refineBehaviourModal,

      // Post Modal functions
      postDeleteNode,
      postAdd,
      postAddPrecond,
      postTiming,
      onRequirementUnlinked,
      renameAfterFunction,
      duplicateAfterFunction,

      // preferences
      showTimings,
      showXrefs,
      toggleTimings,
      toggleXrefs,
      refreshClicked,
      timingNodeIds,
      // search
      focusSearchResult,
      refreshColourBy,
      onClickColourKeyItem,
      canvasClass,
      onChangeColourBy,
      mini,
      miniModeClass,
      onToggleMiniMode,
      refocus,
      focusNodeById,
      highlightNodeById,
      isKeyVisible,
      nodeUpdated,
      btNodeUpdated,
      showGenerateRequirements,
      showGenerateTests,
      exportPdf: exporters.pdf,
      nodeMap,
      hideSidebars,
      expandAll,
      collapseAll,
      isToolboxVisible,
      selectedBehaviourNode,
    }
  },
}
</script>

<style scoped lang="scss">
@import "~@clientio/rappid/rappid.css";
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu.scss';

body {
  height: 60vh;
  box-sizing: border-box;
  margin: 0;

  .content-wrapper {
    .content-body {
      width: 100%;
      height: 100%;
    }
  }

  .canvas {
    width: 100%;
    height: 100%;

    .joint-paper {
      border: 1px solid #000000;
    }
  }

  .toolbar-text {
    display: none;
  }
}

</style>
<style lang="scss">
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu.scss';
@import 'scss/validity.scss';
@import 'scss/coverage.scss';
@import 'scss/nodetype.scss';
@import 'scss/testedness.scss';
@import 'scss/enablement_os.scss';
@import 'scss/enablement_trl.scss';
@import 'scss/stencil.scss';
@import 'scss/badgeValidity.scss';
@import 'scss/search.scss';
@import 'scss/entities.scss';

.canvas-container {
  width: revert;
  max-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;
  top: 7rem;
  overflow-y: clip;
}

.bntxt {
  height: 100%;
  padding: 0.1rem 2rem;
  text-align: center;

  h6 {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    display: inline-block;
    line-height: 1.1;
    margin-bottom: 0.3rem;
    font-size: x-large;
  }
  p {
    line-height: 1;
  }
}

.node-text > * {
  line-height: 3rem;
}

.node-text b {
  color: darkorange;
}

.bn-toolbar {
  position: absolute;
  display: flex;
  align-items: normal;
  gap: 1rem;
  left: 1rem;
  z-index: 1;
  height: 2.75rem;
}

.joint-stencil .search {
  z-index: revert !important;
}

[magnet="true"]:not(.joint-element):hover {
  opacity: 1;
}

.joint-navigator.joint-theme-default {
  top: -200px;
  background-color: rgba(125, 125, 125, 0.3);
  border: none;
  border-top: 2px solid rgba(125, 125, 125, 0.4);
  border-right: 2px solid rgba(125, 125, 125, 0.4);
  border-bottom: 2px solid rgba(125, 125, 125, 0.4);
  border-top-right-radius: 0.428rem;
  border-bottom-right-radius: 0.428rem;
}

.joint-paper.joint-theme-default {
  background-color: rgba(0, 0, 0, 0);
}

.joint-selection.joint-theme-default .selection-box {
  border: 3px solid #a75c0e;
  margin-top: -5px;
  margin-left: -6px;
}

.joint-selection.joint-theme-default .selection-wrapper {
  border: 3px dotted #000000;
  padding-top: 10px;
  padding-left: 10px;
  margin-top: -12px;
  margin-left: -12px;
}

.search-input {
  border-radius: 4px;
  width: 320px;
  background: $body-bg;

  > div {
    height: 2.75rem;
  }
}

.joint-selection.joint-theme-default .handle {
  filter: invert(0.5)
}

.joint-type-standard.joint-theme-default.joint-link {
  color: black;
}

.joint-context-toolbar.joint-theme-default .tool {
  background: #f4f4f4;
  text-align: left;
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-size: 1rem;
  padding: 1rem;
}

.bn-toolbar {
  background: $body-bg;
  .btn {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.joint-context-toolbar.joint-theme-default {
  box-shadow: 1rem 1rem 2rem grey;
}

.joint-context-toolbar.joint-theme-default.joint-vertical .tool:not(:last-child) {
  border-bottom: none;
}

.joint-type-komp-xreflink .labels .label rect {
  fill: $body-bg
}

.link-tools {
  display: none
}

.selection-box {
  pointer-events: none;
}

.joint-theme-default foreignObject {
  fill: black;
  color: black;
}

.collapse-nodes {
  position: absolute;
  padding: 0.5rem;
  font-weight: bold;
  height: 2.5rem;
  background: black;
  border: 1px solid black;
  border-radius: 1rem;
  left: -0.5rem;
}

.bt-probability-text {
  stroke: black;
}
.bt-probability-badge {
  fill: white;
}

body .joint-theme-default .bn-operator-icon:hover {
  fill: $primary;
  cursor: pointer;
}

body.dark-layout ~ .joint-context-toolbar.joint-theme-default {
  box-shadow: 0.1rem 0.1rem 1rem black;
  background-color: $theme-dark-body-bg;
  border-color: $theme-dark-border-color;
  .tool {
    background-color: $theme-dark-body-bg;
    border-color: $theme-dark-border-color;
    color: $theme-dark-body-color;
    text-align: left;
    font-family: "Montserrat", Helvetica, Arial, serif;
    font-size: 1rem;
    padding: 1rem;
  }
  .tool:hover {
    background-color: $theme-dark-body-color;
    color: $theme-dark-body-bg;
  }
}

.joint-type-komp-xreflink.joint-link.joint-theme-default path {
  transition: stroke 2s ease;
}
.joint-type-komp-xreflink.joint-link.joint-theme-default path:hover {
  stroke: #4a4afa3f;
  stroke-width: 20;
  transition: stroke 0s;
}

.joint-type-komp-xreflink.labels .label rect {
  fill: white;
}

body.dark-layout {
  .btn-outline-secondary {
    color: #aab0c6;
    border-color: #9ba5b3;
  }

  .joint-type-komp-xreflink.labels .label rect {
    fill: $theme-dark-card-bg;
  }
  .joint-type-komp-xreflink.labels .label text {
    fill: $theme-dark-label-color;
  }

  .search-input {
    background: $theme-dark-body-bg;
  }

  .bn-toolbar {
    background: $theme-dark-body-bg;
  }

  .joint-type-komp .bn[stroke="black"] {
    stroke: gray;
  }

  .joint-selection.joint-theme-default .selection-wrapper {
    border: 3px dotted #a7a7a7;
  }

  .joint-halo.joint-theme-default .handle {
    filter: invert(0.9) drop-shadow(1px 1px 2px #222);
  }

  .joint-selection.joint-theme-default .selection-box {
    border: 3px solid #F8A63B;
  }

  .joint-type-standard.joint-theme-default.joint-link {
    filter: invert(1) contrast(0.5);
  }

  .joint-theme-default foreignObject {
    fill: lightgrey;
    color: lightgrey;
    text-shadow: 1px 1px 3px black;
  }

  .joint-theme-default text {
    fill: lightgrey;
    text-shadow: 1px 1px 3px black;
  }

  .bt-probability-text {
    stroke: white;
  }

  .bt-probability-badge {
    fill: black;
  }

}
</style>
